import {
  Button,
  Card,
  Col,
  Form,
  Icon,
  InputNumber,
  Modal,
  Row,
  Select,
  notification,
  Input,
  Checkbox
} from 'antd'
import {
  GET_PLAYERS_WITH_TEAMS_BY_NAME,
  GET_TEAMS_WITH_MANAGERS
} from '../../queries'
import React, {useEffect, useState} from 'react'
import {useLazyQuery, useMutation, useQuery} from '@apollo/client'

import debounce from 'lodash/debounce'
import {gql} from 'apollo-boost'

const UPDATE_TEAM = gql`
  mutation updateTeam(
    $id: String!
    $alternativeName: String
    $budget: Float!
    $customReceivedClausesEnabled: Boolean!
    $receivedClauses: Float!
    $customPayedClausesEnabled: Boolean!
    $payedClauses: Float!
    $players: [String!]!
  ) {
    updateTeam(
      updateTeamData: {
        id: $id
        alternativeName: $alternativeName
        budget: $budget
        customReceivedClausesEnabled: $customReceivedClausesEnabled
        receivedClauses: $receivedClauses
        customPayedClausesEnabled: $customPayedClausesEnabled
        payedClauses: $payedClauses
        players: $players
      }
    )
  }
`

export default ({activeTabKey}) => {
  const searchUsers = value => {
    if (value.length >= 3) {
      fetchUsers({
        variables: {
          name: value
        }
      })
    }
  }

  const [teams, setTeams] = useState([])
  const [players, setPlayers] = useState([])
  const [selectedTeam, setSelectedTeam] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [teamBudget, setTeamBudget] = useState(false)
  const [teamAlternativeName, setTeamAlternativeName] = useState(false)
  const [playersInTeam, setPlayersInTeam] = useState([])
  const [debouncedSearch] = useState(() => debounce(searchUsers, 250))
  const [customReceivedClausesEnabled, setCustomReceivedClausesEnabled] =
    useState(false)
  const [customPayedClausesEnabled, setCustomPayedClausesEnabled] =
    useState(false)
  const [receivedClauses, setReceivedClauses] = useState(0)
  const [payedClauses, setPayedClauses] = useState(0)

  const selectTeam = team => {
    setSelectedTeam(team)
    setTeamBudget(team.budget)
    setTeamAlternativeName(team.alternativeName)
    setPlayersInTeam(() =>
      team && team.players ? team.players.map(player => player.id) : []
    )
    setReceivedClauses(team.receivedClauses)
    setPayedClauses(team.payedClauses)
    setCustomReceivedClausesEnabled(team.customReceivedClausesEnabled)
    setCustomPayedClausesEnabled(team.customPayedClausesEnabled)
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)

    try {
      const response = await updateTeam({
        variables: {
          id: selectedTeam.id,
          alternativeName: teamAlternativeName,
          budget: teamBudget,
          customReceivedClausesEnabled,
          receivedClauses,
          customPayedClausesEnabled,
          payedClauses,
          players: playersInTeam
        }
      })

      if (response && response.data && response.data.updateTeam) {
        notification.success({
          message: '¡Éxito!',
          description: 'El equipo se actualizó correctamente.'
        })
      }
    } catch (err) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description:
          'Ocurrió algún problema al guardar el equipo. Por favor, inténtalo de nuevo más tarde'
      })
    }

    setIsSubmitting(false)
    setSelectedTeam(false)
  }

  const {data, error, loading, refetch} = useQuery(GET_TEAMS_WITH_MANAGERS, {
    fetchPolicy: 'network-only'
  })
  const [
    fetchUsers,
    {data: playersData, error: playersError, loading: playersLoading}
  ] = useLazyQuery(GET_PLAYERS_WITH_TEAMS_BY_NAME, {
    fetchPolicy: 'network-only'
  })

  const [updateTeam] = useMutation(UPDATE_TEAM, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (playersData && !playersError) {
      setPlayers(playersData.players)
    }
  }, [playersData, playersError])

  useEffect(() => {
    if (data && !error) {
      setTeams(data.teams)
    }
  }, [data, error])

  useEffect(() => {
    if (activeTabKey === '2' && data && !loading) {
      refetch()
    }
    // eslint-disable-next-line
  }, [activeTabKey])

  return (
    <Row id="teams-list" gutter={16}>
      {teams.map(team => (
        <React.Fragment key={team.id}>
          <Col xs={12} md={6}>
            <Card
              title={team.name}
              style={{textAlign: 'center', marginBottom: '2vh'}}
            >
              <Button
                onClick={() => selectTeam(team)}
                icon="edit"
                shape="round"
                type="primary"
              >
                Editar
              </Button>
            </Card>
          </Col>

          <Modal
            visible={selectedTeam.id === team.id}
            title="Editar equipo"
            cancelText="Cancelar"
            okButtonProps={{loading: isSubmitting, disabled: isSubmitting}}
            okText="Guardar"
            onCancel={() => setSelectedTeam(false)}
            onOk={handleSubmit}
          >
            <Form>
              <Form.Item label="Nombre alternativo">
                <Input
                  style={{width: '100%'}}
                  placeholder="Nombre alternativo"
                  defaultValue={team.alternativeName}
                  onChange={e => setTeamAlternativeName(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Presupuesto">
                <InputNumber
                  style={{width: '100%'}}
                  placeholder="Presupuesto del equipo"
                  defaultValue={team.budget}
                  formatter={value => `${value} M`}
                  parser={value => value.replace(' M', '')}
                  onChange={value => setTeamBudget(value)}
                />
              </Form.Item>

              <Form.Item style={{marginBottom: 0}}>
                <Checkbox
                  checked={customReceivedClausesEnabled}
                  onChange={e =>
                    setCustomReceivedClausesEnabled(e.target.checked)
                  }
                >
                  Personalizar importe de cláusulas recibidas
                </Checkbox>
              </Form.Item>

              {customReceivedClausesEnabled && (
                <Form.Item style={{marginBottom: 0}}>
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Importe personalizado de cláusulas recibidas"
                    defaultValue={receivedClauses}
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                    onChange={value => setReceivedClauses(value)}
                  />
                </Form.Item>
              )}

              <Form.Item style={{marginBottom: 0}}>
                <Checkbox
                  checked={customPayedClausesEnabled}
                  onChange={e => setCustomPayedClausesEnabled(e.target.checked)}
                >
                  Personalizar importe de cláusulas pagadas
                </Checkbox>
              </Form.Item>

              {customPayedClausesEnabled && (
                <Form.Item style={{marginBottom: 0}}>
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Importe personalizado de cláusulas pagadas"
                    defaultValue={payedClauses}
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                    onChange={value => setPayedClauses(value)}
                  />
                </Form.Item>
              )}

              <Form.Item label="Jugadores">
                <Select
                  onChange={value =>
                    setPlayersInTeam(() => value.map(item => item.key))
                  }
                  onSearch={value => debouncedSearch(value)}
                  defaultValue={
                    team && team.players
                      ? team.players.map(player => {
                          return {key: player.id, label: player.name}
                        })
                      : []
                  }
                  labelInValue
                  filterOption={false}
                  notFoundContent={
                    playersLoading ? <Icon type="sync" spin /> : null
                  }
                  mode="multiple"
                  placeholder="Jugadores del equipo"
                >
                  {players.map(player => (
                    <Select.Option key={player.id}>{player.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        </React.Fragment>
      ))}
    </Row>
  )
}
