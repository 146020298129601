import {Form, Input, InputNumber, Select} from 'antd'

import React from 'react'

const AddPlayerForm = ({form}) => {
  const {getFieldDecorator, getFieldError, isFieldTouched} = form

  const publicIdError = isFieldTouched('publicId') && getFieldError('publicId')
  const nameError = isFieldTouched('name') && getFieldError('name')
  const ratingError = isFieldTouched('rating') && getFieldError('rating')
  const positionError = isFieldTouched('position') && getFieldError('position')
  const officialTeamError =
    isFieldTouched('officialTeam') && getFieldError('officialTeam')

  return (
    <div>
      <Form>
        <Form.Item
          label="ID de PesDB"
          validateStatus={publicIdError ? 'error' : ''}
          help={publicIdError ? publicIdError : ''}
        >
          <div style={{lineHeight: 1.2, fontSize: '.75rem'}}>
            (Número indicado en la URL de la ficha del jugador, después de
            "?id=)
          </div>

          {getFieldDecorator('publicId', {
            rules: [
              {required: true, message: 'Necesitas indicar esta información.'}
            ]
          })(<InputNumber style={{width: '100%'}} placeholder="ID de PesDB" />)}
        </Form.Item>

        <Form.Item
          label="Nombre del jugador"
          validateStatus={nameError ? 'error' : ''}
          help={nameError ? nameError : ''}
        >
          {getFieldDecorator('name', {
            rules: [
              {required: true, message: 'Necesitas indicar esta información.'}
            ]
          })(<Input placeholder="Nombre del jugador" />)}
        </Form.Item>

        <Form.Item
          label="Puntuación media"
          validateStatus={ratingError ? 'error' : ''}
          help={ratingError ? ratingError : ''}
        >
          <div style={{lineHeight: 1.2, fontSize: '.75rem'}}>
            (Número indicado en la ficha del jugador, en el apartado "Overall
            Rating")
          </div>

          {getFieldDecorator('rating', {
            rules: [
              {required: true, message: 'Necesitas indicar esta información.'}
            ]
          })(
            <InputNumber
              style={{width: '100%'}}
              min={1}
              max={100}
              placeholder="Puntuación media"
            />
          )}
        </Form.Item>
        <Form.Item
          label="Posición"
          validateStatus={positionError ? 'error' : ''}
          help={positionError ? positionError : ''}
        >
          <div style={{lineHeight: 1.2, fontSize: '.75rem'}}>
            (Acrónimo de la posición tal y como se indican en la ficha del
            jugador en el apartado "Position")
          </div>

          {getFieldDecorator('position', {
            rules: [
              {required: true, message: 'Necesitas indicar esta información.'}
            ]
          })(
            <Select placeholder="Posición">
              <Select.Option key="GK">GK</Select.Option>
              <Select.Option key="RMF">RMF</Select.Option>
              <Select.Option key="DMF">DMF</Select.Option>
              <Select.Option key="CB">CB</Select.Option>
              <Select.Option key="CF">CF</Select.Option>
              <Select.Option key="LB">LB</Select.Option>
              <Select.Option key="RB">RB</Select.Option>
              <Select.Option key="AMF">AMF</Select.Option>
              <Select.Option key="RWF">RWF</Select.Option>
              <Select.Option key="SS">SS</Select.Option>
              <Select.Option key="LWF">LWF</Select.Option>
              <Select.Option key="LMF">LMF</Select.Option>
              <Select.Option key="CMF">CMF</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label="Equipo original"
          validateStatus={officialTeamError ? 'error' : ''}
          help={officialTeamError ? officialTeamError : ''}
        >
          <div style={{lineHeight: 1.2, fontSize: '.75rem'}}>
            (Equipo original y oficial al que pertenece el jugador, tal y como
            se indica en la ficha del jugador en el apartado "Team Name")
          </div>
          {getFieldDecorator('officialTeam', {
            rules: [
              {required: true, message: 'Necesitas indicar esta información.'}
            ]
          })(<Input placeholder="Equipo original" />)}
        </Form.Item>
      </Form>
    </div>
  )
}

export default Form.create({name: 'AddPlayerForm'})(AddPlayerForm)
