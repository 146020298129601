import React, {useContext} from 'react'

import AppAuth from './AppAuth'
import AppLoading from './AppLoading'
import Platform from './Platform'
import {SettingsContext} from './SettingsContext'
import {UserContext} from './UserContext'

export default () => {
  const user = useContext(UserContext)
  const settings = useContext(SettingsContext)

  return (
    <div id="app" style={{width: '100%', height: '100vh'}}>
      {user && user.loading && <AppLoading />}

      {user && !user.loading && settings && settings.loaded && (
        <div>
          {user.isAuthenticated && <Platform />}

          {!user.isAuthenticated && <AppAuth />}
        </div>
      )}
    </div>
  )
}
