import {useContext, useEffect, useState} from 'react'

import {SettingsContext} from '../SettingsContext'
import {UserContext} from '../UserContext'

export default () => {
  const [enabled, setEnabled] = useState(false)
  const settingsContext = useContext(SettingsContext)
  const user = useContext(UserContext)

  useEffect(() => {
    const now = Math.floor(Date.now() / 1000)

    if (
      settingsContext &&
      settingsContext.settings.payClausesPeriodStart &&
      settingsContext.settings.payClausesPeriodEnd &&
      settingsContext.settings.payClausesPeriodStart <= now &&
      settingsContext.settings.payClausesPeriodEnd >= now &&
      user &&
      user.team &&
      user.team.boughtClauses <= settingsContext.settings.maxBoughtClauses
    ) {
      setEnabled(true)
    }
  }, [settingsContext, user])

  return enabled
}
