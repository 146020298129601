import {
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  InputNumber,
  Popconfirm,
  Row,
  notification
} from 'antd'
import React, {useEffect, useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'

import {SETTINGS} from '../../queries'
import formHasErrors from '../../../helpers/formHasErrors'
import {gql} from 'apollo-boost'
import moment from 'moment'

const UPDATE_SETTINGS = gql`
  mutation updateSettings($data: JSON!) {
    updateSettings(updateSettingsData: {data: $data})
  }
`

const APPLY_SALARIES = gql`
  mutation applySalaries {
    applySalaries
  }
`

const RESET_CLAUSES = gql`
  mutation enableClausePurchases {
    enableClausePurchases
  }
`

export const CLEAR_DEALS = gql`
  mutation clearDeals {
    clearDeals
  }
`

const SettingsPanelForm = ({form}) => {
  const {
    getFieldDecorator,
    validateFields,
    getFieldError,
    getFieldsError,
    isFieldTouched
  } = form
  const [settings, setSettings] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const {data, error, loading, refetch} = useQuery(SETTINGS, {
    fetchPolicy: 'network-only'
  })
  const [updateSettings] = useMutation(UPDATE_SETTINGS, {
    fetchPolicy: 'no-cache',
    refetchQueries: [{query: SETTINGS}]
  })

  const [applySalaries, {loading: applySalariesLoading}] = useMutation(
    APPLY_SALARIES,
    {
      fetchPolicy: 'no-cache',
      refetchQueries: [{query: SETTINGS}]
    }
  )

  const [resetClauses, {loading: resetClausesLoading}] = useMutation(
    RESET_CLAUSES,
    {
      fetchPolicy: 'no-cache',
      refetchQueries: [{query: SETTINGS}]
    }
  )

  const [clearDeals, {loading: clearDealsLoading}] = useMutation(CLEAR_DEALS, {
    fetchPolicy: 'no-cache',
    refetchQueries: [{query: SETTINGS}]
  })

  const handleApplySalaries = async () => {
    try {
      await applySalaries()

      notification.success({
        message: '¡Éxito!',
        description: 'Se han aplicado los salarios correctamente.'
      })
    } catch (err) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description:
          'Los salarios no se pudieron aplicar correctamente. Contacta con el administrador.'
      })
    }
  }

  const handleResetClauses = async () => {
    try {
      await resetClauses()

      notification.success({
        message: '¡Éxito!',
        description: 'Se han reiniciado las cláusulas correctamente.'
      })
    } catch (err) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description:
          'Los salarios no se pudieron aplicar correctamente. Contacta con el administrador.'
      })
    }
  }

  const handleClearDeals = async () => {
    try {
      await clearDeals()

      notification.success({
        message: '¡Éxito!',
        description: 'Se han eliminado los registros de acuerdos correctamente.'
      })
    } catch (err) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description:
          'Los registros de acuerdos no se pudieron eliminar correctamente. Contacta con el administrador.'
      })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    validateFields(async (err, values) => {
      if (!err) {
        const processedData = {...values}

        if (processedData.updateClausePeriod !== 0) {
          processedData.updateClausePeriodStart = parseInt(
            Math.floor(processedData.updateClausePeriod[0].format('X'))
          )
          processedData.updateClausePeriodEnd = parseInt(
            Math.floor(processedData.updateClausePeriod[1].format('X'))
          )
        }

        if (processedData.makeDealsPeriod !== 0) {
          processedData.makeDealsPeriodStart = parseInt(
            Math.floor(processedData.makeDealsPeriod[0].format('X'))
          )
          processedData.makeDealsPeriodEnd = parseInt(
            Math.floor(processedData.makeDealsPeriod[1].format('X'))
          )
        }

        if (processedData.payClausesPeriod !== 0) {
          processedData.payClausesPeriodStart = parseInt(
            Math.floor(processedData.payClausesPeriod[0].format('X'))
          )
          processedData.payClausesPeriodEnd = parseInt(
            Math.floor(processedData.payClausesPeriod[1].format('X'))
          )
        }

        if (processedData.layoffsPeriod !== 0) {
          processedData.layoffsPeriodStart = parseInt(
            Math.floor(processedData.layoffsPeriod[0].format('X'))
          )
          processedData.layoffsPeriodEnd = parseInt(
            Math.floor(processedData.layoffsPeriod[1].format('X'))
          )
        }

        delete processedData.updateClausePeriod
        delete processedData.makeDealsPeriod
        delete processedData.payClausesPeriod
        delete processedData.layoffsPeriod

        try {
          await updateSettings({
            variables: {data: processedData}
          })
          await refetch()
          notification.success({
            message: '¡Éxito!',
            description: 'Los ajustes se guardaron correctamente.'
          })
        } catch (err) {
          notification.error({
            message: '¡Ha ocurrido un error!',
            description:
              'Los ajustes no se pudieron guardar correctamente. Por favor, inténtalo de nuevo más adelante.'
          })
          setSubmitting(false)
        }
      }
    })
    setSubmitting(false)
  }

  useEffect(() => {
    if (data && !error) {
      setSettings(data.settings.data)
    }
  }, [data, error])

  useEffect(() => {
    if (settings) {
      validateFields()
    }
    // eslint-disable-next-line
  }, [settings])

  const updateClausePeriodError =
    isFieldTouched('updateClausePeriod') && getFieldError('updateClausePeriod')
  const makeDealsPeriodError =
    isFieldTouched('makeDealsPeriod') && getFieldError('makeDealsPeriod')
  const payClausesPeriodError =
    isFieldTouched('payClausesPeriod') && getFieldError('payClausesPeriod')
  const layoffsPeriodError =
    isFieldTouched('layoffsPeriod') && getFieldError('layoffsPeriod')

  return (
    <div id="settings-panel" style={{textAlign: 'left'}}>
      {loading ? (
        <Icon type="sync" style={{fontSize: '2.5rem'}} spin />
      ) : (
        <Form layout="inline" onSubmit={handleSubmit}>
          <Row type="flex" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="IVA de las cláusulas"
              >
                {getFieldDecorator('clausesTaxes', {
                  initialValue: settings.clausesTaxes
                    ? settings.clausesTaxes
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="IVA de las cláusulas"
                    formatter={value => `${value} %`}
                    parser={value => value.replace(' %', '')}
                  />
                )}
              </Form.Item>

              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Máximo nº de cláusulas para vender"
              >
                {getFieldDecorator('maxSoldClauses', {
                  initialValue: settings.maxSoldClauses
                    ? settings.maxSoldClauses
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Máximo nº de cláusulas para vender"
                  />
                )}
              </Form.Item>

              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Máximo nº de cláusulas para comprar"
              >
                {getFieldDecorator('maxBoughtClauses', {
                  initialValue: settings.maxBoughtClauses
                    ? settings.maxBoughtClauses
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Máximo nº de cláusulas para comprar"
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                label="Periodo de modificación de cláusulas"
                validateStatus={updateClausePeriodError ? 'error' : ''}
                help={updateClausePeriodError || ''}
              >
                {getFieldDecorator('updateClausePeriod', {
                  initialValue:
                    settings.updateClausePeriodStart &&
                    settings.updateClausePeriodEnd
                      ? [
                          moment.unix(settings.updateClausePeriodStart),
                          moment.unix(settings.updateClausePeriodEnd)
                        ]
                      : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <DatePicker.RangePicker
                    style={{width: '100%'}}
                    format={'DD/MM/YYYY HH:mm:ss'}
                    showTime={true}
                    placeholder={['Fecha de inicio', 'Fecha de fin']}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                label="Periodo de acuerdos"
                validateStatus={makeDealsPeriodError ? 'error' : ''}
                help={makeDealsPeriodError || ''}
              >
                {getFieldDecorator('makeDealsPeriod', {
                  initialValue:
                    settings.makeDealsPeriodStart && settings.makeDealsPeriodEnd
                      ? [
                          moment.unix(settings.makeDealsPeriodStart),
                          moment.unix(settings.makeDealsPeriodEnd)
                        ]
                      : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <DatePicker.RangePicker
                    style={{width: '100%'}}
                    format={'DD/MM/YYYY HH:mm:ss'}
                    showTime={true}
                    placeholder={['Fecha de inicio', 'Fecha de fin']}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                label="Periodo de pago de cláusulas"
                validateStatus={payClausesPeriodError ? 'error' : ''}
                help={payClausesPeriodError || ''}
              >
                {getFieldDecorator('payClausesPeriod', {
                  initialValue:
                    settings.payClausesPeriodStart &&
                    settings.payClausesPeriodEnd
                      ? [
                          moment.unix(settings.payClausesPeriodStart),
                          moment.unix(settings.payClausesPeriodEnd)
                        ]
                      : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <DatePicker.RangePicker
                    style={{width: '100%'}}
                    format={'DD/MM/YYYY HH:mm:ss'}
                    showTime={true}
                    placeholder={['Fecha de inicio', 'Fecha de fin']}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                label="Periodo de despidos"
                validateStatus={layoffsPeriodError ? 'error' : ''}
                help={layoffsPeriodError || ''}
              >
                {getFieldDecorator('layoffsPeriod', {
                  initialValue:
                    settings.layoffsPeriodStart && settings.layoffsPeriodEnd
                      ? [
                          moment.unix(settings.layoffsPeriodStart),
                          moment.unix(settings.layoffsPeriodEnd)
                        ]
                      : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <DatePicker.RangePicker
                    style={{width: '100%'}}
                    format={'DD/MM/YYYY HH:mm:ss'}
                    showTime={true}
                    placeholder={['Fecha de inicio', 'Fecha de fin']}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={{span: 12, offset: 0}} md={{span: 11, offset: 1}}>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Precio de jugadores <=70"
              >
                {getFieldDecorator('freePlayers70HirePrice', {
                  initialValue: settings.freePlayers70HirePrice
                    ? settings.freePlayers70HirePrice
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Precio de jugadores <=70"
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Precio de jugadores 71 a 75"
              >
                {getFieldDecorator('freePlayers75HirePrice', {
                  initialValue: settings.freePlayers75HirePrice
                    ? settings.freePlayers75HirePrice
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Precio de jugadores 71 a 75"
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Precio de jugadores 76 a 80"
              >
                {getFieldDecorator('freePlayers80HirePrice', {
                  initialValue: settings.freePlayers80HirePrice
                    ? settings.freePlayers80HirePrice
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Precio de jugadores 76 a 80"
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Precio de jugadores 81 a 85"
              >
                {getFieldDecorator('freePlayers85HirePrice', {
                  initialValue: settings.freePlayers85HirePrice
                    ? settings.freePlayers85HirePrice
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Precio de jugadores 81 a 85"
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Precio de jugadores >= 86"
              >
                {getFieldDecorator('freePlayers86HirePrice', {
                  initialValue: settings.freePlayers86HirePrice
                    ? settings.freePlayers86HirePrice
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Precio de jugadores >= 86"
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Despido de jugadores <= 70"
              >
                {getFieldDecorator('freePlayers70FirePrice', {
                  initialValue: settings.freePlayers70FirePrice
                    ? settings.freePlayers70FirePrice
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Despido de jugadores <= 70"
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Despido de jugadores 71 a 75"
              >
                {getFieldDecorator('freePlayers75FirePrice', {
                  initialValue: settings.freePlayers75FirePrice
                    ? settings.freePlayers75FirePrice
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Despido de jugadores 71 a 75"
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Despido de jugadores 76 a 80"
              >
                {getFieldDecorator('freePlayers80FirePrice', {
                  initialValue: settings.freePlayers80FirePrice
                    ? settings.freePlayers80FirePrice
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Despido de jugadores 76 a 80"
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Despido de jugadores 81 a 85"
              >
                {getFieldDecorator('freePlayers85FirePrice', {
                  initialValue: settings.freePlayers85FirePrice
                    ? settings.freePlayers85FirePrice
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Despido de jugadores 81 a 85"
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                  />
                )}
              </Form.Item>
              <Form.Item
                style={{width: '100%'}}
                labelAlign="left"
                labelCol={{span: 18}}
                wrapperCol={{span: 6}}
                label="Despido de jugadores >= 86"
              >
                {getFieldDecorator('freePlayers86FirePrice', {
                  initialValue: settings.freePlayers86FirePrice
                    ? settings.freePlayers86FirePrice
                    : 0,
                  rules: [
                    {
                      required: true,
                      message: '¡Necesitas indicar esta información!'
                    }
                  ]
                })(
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder="Despido de jugadores >= 86"
                    formatter={value => `${value} M`}
                    parser={value => value.replace(' M', '')}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{marginTop: '5vh', textAlign: 'center'}}>
            <Popconfirm
              title="¿Seguro que quieres finalizar la temporada?"
              onConfirm={handleApplySalaries}
              okText="Sí"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{marginLeft: '2.5vw'}}
                loading={applySalariesLoading}
                disabled={applySalariesLoading}
              >
                Aplicar salarios
              </Button>
            </Popconfirm>

            <Popconfirm
              title="¿Seguro que quieres reiniciar las cláusulas?"
              onConfirm={handleResetClauses}
              okText="Sí"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{marginLeft: '2.5vw'}}
                loading={resetClausesLoading}
                disabled={resetClausesLoading}
              >
                Reinciar cláusulas
              </Button>
            </Popconfirm>

            <Popconfirm
              title="¿Seguro que quieres eliminar los registros de acuerdos y compraventas?"
              onConfirm={handleClearDeals}
              okText="Sí"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{marginLeft: '2.5vw'}}
                loading={clearDealsLoading}
                disabled={clearDealsLoading}
              >
                Limpiar acuerdos
              </Button>
            </Popconfirm>
          </Row>
          <Form.Item
            style={{marginTop: '3vh', textAlign: 'right', width: '100%'}}
          >
            <Button
              htmlType="submit"
              type="primary"
              loading={submitting}
              disabled={formHasErrors(getFieldsError()) || submitting}
            >
              Guardar
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  )
}

export default Form.create({name: 'SettingsPanelForm'})(SettingsPanelForm)
