import {Form, InputNumber, Select} from 'antd'
import React, {useContext} from 'react'

import {UserContext} from '../../UserContext'

const AgreementForm = ({form}) => {
  const {getFieldDecorator} = form
  const user = useContext(UserContext)

  return (
    <Form>
      <Form.Item label="Dinero">
        {getFieldDecorator('money', {
          initialValue: 0
        })(
          <InputNumber
            placeholder="Dinero ofrecido para el acuerdo"
            formatter={value => `${value} M`}
            parser={value => value.replace(' M', '')}
            min={0}
            max={user.team.budget}
          />
        )}
      </Form.Item>

      <Form.Item label="Jugadores">
        {getFieldDecorator('players')(
          <Select
            placeholder="Jugadores ofrecidos para el acuerdo"
            mode="multiple"
          >
            {user.team.players.map(player => (
              <Select.Option key={player.id}>{player.name}</Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    </Form>
  )
}

export default Form.create({name: 'AgreementForm'})(AgreementForm)
