import React, {useContext, useEffect, useState} from 'react'

import Team from '../Team'
import {UserContext} from '../../UserContext'

export default ({history}) => {
  const user = useContext(UserContext)
  const [teamId, setTeamId] = useState(false)

  useEffect(() => {
    if (!user.team || !user.team.id) {
      history.push('/')
    } else {
      setTeamId(user.team.id)
    }
    // eslint-disable-next-line
  }, [])

  if (!teamId) {
    return <span />
  }

  return <Team teamId={user.team.id} />
}
