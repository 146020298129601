import {ACCEPT_AGREEMENT, CANCEL_AGREEMENT, USER} from '../../Platform/queries'
import {
  Button,
  Card,
  Col,
  Icon,
  Popconfirm,
  Popover,
  Row,
  Spin,
  Typography,
  notification
} from 'antd'
import React, {useEffect, useState} from 'react'

import {useMutation} from '@apollo/client'

export default ({deal, infoOnly, pastDeal}) => {
  const [ballRatingImgUrl, setBallRatingImgUrl] = useState(
    'http://pesdb.net/pes2021/images/ball4.png'
  )

  const [acceptAgreement, {loading}] = useMutation(ACCEPT_AGREEMENT, {
    variables: {
      dealId: deal.id
    },
    fetchPolicy: 'no-cache',
    refetchQueries: [{query: USER}]
  })

  const [cancelAgreement, {loading: cancelLoading}] = useMutation(
    CANCEL_AGREEMENT,
    {
      variables: {
        dealId: deal.id
      },
      fetchPolicy: 'no-cache',
      refetchQueries: [{query: USER}]
    }
  )

  const handleAccept = async () => {
    if (
      deal.moneyOffered &&
      deal.moneyOffered > 0 &&
      deal.buyer.team.budget < deal.moneyOffered
    ) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description:
          'El comprador ya no cuenta con el presupuesto necesario para pagar la transacción.'
      })
      return
    }

    if (
      deal.buyer &&
      deal.buyer.team &&
      deal.buyer.team.players &&
      deal.playersOffered.some(
        playerOffered =>
          !deal.buyer.team.players.find(
            player => player.id === playerOffered.id
          )
      )
    ) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description:
          'El comprador ya no cuenta con los jugadores ofrecidos para la transacción.'
      })
      return
    }

    try {
      await acceptAgreement()

      notification.success({
        message: '¡Éxito!',
        description: 'Has aceptado el acuerdo correctamente.'
      })

      window.location.reload()
    } catch (err) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description: err.graphQLErrors[0].message
      })
    }
  }

  const handleCancel = async () => {
    await cancelAgreement()

    notification.success({
      message: '¡Éxito!',
      description: 'Has cancelado el acuerdo correctamente.'
    })

    window.location.reload()
  }

  useEffect(() => {
    let img = null

    if (deal.playerRequested.rating >= 85) {
      img = 'http://pesdb.net/pes2021/images/ball4.png'
    } else if (deal.playerRequested.rating >= 80) {
      img = 'http://pesdb.net/pes2021/images/ball3.png'
    } else if (deal.playerRequested.rating >= 75) {
      img = 'http://pesdb.net/pes2021/images/ball2.png'
    } else if (deal.playerRequested.rating >= 70) {
      img = 'http://pesdb.net/pes2021/images/ball1.png'
    } else {
      img = 'http://pesdb.net/pes2021/images/ball0.png'
    }

    setBallRatingImgUrl(img)
    // eslint-disable-next-line
  }, [])

  return (
    <div className="deal-card-wrapper">
      <Card
        bodyStyle={{padding: '0'}}
        className={`deal-card ${pastDeal ? 'past-deal' : ''}`}
        actions={
          infoOnly || pastDeal
            ? false
            : [
                <Popconfirm
                  title="¿Seguro que quieres aceptar el acuerdo?"
                  onConfirm={handleAccept}
                  okText="Sí"
                  cancelText="No"
                >
                  <Icon type="check" />
                </Popconfirm>,
                <Popconfirm
                  title="¿Seguro que quieres cancelar el acuerdo?"
                  onConfirm={handleCancel}
                  okText="Sí"
                  cancelText="No"
                >
                  {' '}
                  <Icon type="stop" />
                </Popconfirm>
              ]
        }
      >
        <Spin spinning={loading || cancelLoading ? true : false}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '30px',
              padding: '10px'
            }}
          >
            <div>
              <Typography.Text strong>
                {deal.playerRequested.name}
              </Typography.Text>
            </div>
            <div style={{textAlign: 'right'}}>
              <span>{deal.playerRequested.rating} </span>
              <img
                src={ballRatingImgUrl}
                alt=""
                style={{maxWidth: '20px', height: 'auto'}}
              />
            </div>
          </div>

          <div style={{padding: '10px 10px 0'}}>
            <Row type="flex" align={infoOnly ? 'bottom' : 'middle'}>
              <Col span={5}>
                <img
                  src={`http://pesdb.net/pes2021/images/players/${deal.playerRequested.publicId}.png`}
                  alt=""
                  style={{maxWidth: '100%', height: 'auto'}}
                />
              </Col>
              <Col span={19}>
                <TeamInfoLayout infoOnly={infoOnly} deal={deal} />
              </Col>
            </Row>
          </div>

          <TeamInfoFooterLayout infoOnly={infoOnly} deal={deal} />
        </Spin>
      </Card>
    </div>
  )
}

const TeamInfoLayout = ({infoOnly, deal}) => {
  if (infoOnly) {
    switch (deal.type) {
      case 'AGREEMENT':
        return <TeamInfoLayoutAgreement deal={deal} />
      case 'BOUGHT':
      case 'FREEPLAYER':
        return <TeamInfoLayoutClause deal={deal} />
      default:
        return <TeamInfoLayoutLayoff deal={deal} />
    }
  } else {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          paddingLeft: '12px'
        }}
      >
        <Icon type="export" style={{fontSize: '2rem', color: 'green'}} />
        <div style={{paddingLeft: '12px', fontSize: '1rem'}}>
          <div style={{lineHeight: 1, fontWeight: 600}}>
            {deal.buyer.team &&
              (deal.buyer.team.alternativeName
                ? deal.buyer.team.alternativeName
                : deal.buyer.team.name)}
          </div>
          <div style={{lineHeight: 1}}>{deal.buyer.username}</div>
        </div>
        {deal.status === 'ACCEPTED' && (
          <Icon
            type="check"
            style={{marginLeft: 'auto', fontSize: '1.75rem', color: 'green'}}
          />
        )}

        {deal.status === 'REJECTED' && (
          <Icon
            type="close"
            style={{marginLeft: 'auto', fontSize: '1.75rem', color: 'red'}}
          />
        )}
      </div>
    )
  }
}

const TeamInfoLayoutAgreement = ({deal}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        height: '100%',
        paddingLeft: '12px'
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '1.25vw'
        }}
      >
        <Icon type="export" style={{fontSize: '1.25rem', color: 'red'}} />
        <div style={{fontSize: '.75rem', paddingLeft: '.5vw'}}>
          <div style={{lineHeight: 1, fontWeight: 600}}>
            {deal.receiver.team &&
              (deal.receiver.team.alternativeName
                ? deal.receiver.team.alternativeName
                : deal.receiver.team.name)}
          </div>
          <div style={{lineHeight: 1}}>{deal.receiver.username}</div>
        </div>
      </div>
      <div style={{width: '100%', display: 'flex', padding: '1.5vh 0 1vh'}}>
        <Icon type="import" style={{fontSize: '2rem', color: 'green'}} />
        <div style={{paddingLeft: '12px', fontSize: '1rem'}}>
          <div style={{lineHeight: 1, fontWeight: 600}}>
            {deal.buyer.team &&
              (deal.buyer.team.alternativeName
                ? deal.buyer.team.alternativeName
                : deal.buyer.team.name)}
          </div>
          <div style={{lineHeight: 1}}>{deal.buyer.username}</div>
        </div>
      </div>
    </div>
  )
}

const TeamInfoLayoutClause = ({deal}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        height: '100%',
        paddingLeft: '12px'
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '1.25vw'
        }}
      >
        <Icon type="export" style={{fontSize: '1.25rem', color: 'red'}} />
        <div style={{fontSize: '.75rem', paddingLeft: '.5vw'}}>
          {deal.receiver && (
            <React.Fragment>
              <div style={{lineHeight: 1, fontWeight: 600}}>
                {deal.receiver.team &&
                  (deal.receiver.team.alternativeName
                    ? deal.receiver.team.alternativeName
                    : deal.receiver.team.name)}
              </div>
              <div style={{lineHeight: 1}}>{deal.receiver.username}</div>
            </React.Fragment>
          )}

          {!deal.receiver && (
            <div style={{lineHeight: 1, fontWeight: 600}}>Jugadores libres</div>
          )}
        </div>
      </div>
      <div style={{width: '100%', display: 'flex', padding: '1.5vh 0 1vh'}}>
        <Icon type="import" style={{fontSize: '2rem', color: 'green'}} />
        <div style={{paddingLeft: '12px', fontSize: '1rem'}}>
          <div style={{lineHeight: 1, fontWeight: 600}}>
            {deal.buyer.team &&
              (deal.buyer.team.alternativeName
                ? deal.buyer.team.alternativeName
                : deal.buyer.team.name)}
          </div>
          <div style={{lineHeight: 1}}>{deal.buyer.username}</div>
        </div>
      </div>
    </div>
  )
}

const TeamInfoLayoutLayoff = ({deal}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        height: '100%',
        paddingLeft: '12px'
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '1.25vw'
        }}
      >
        <Icon type="export" style={{fontSize: '1.25rem', color: 'red'}} />
        <div style={{fontSize: '.75rem', paddingLeft: '.5vw'}}>
          <div style={{lineHeight: 1, fontWeight: 600}}>
            {deal.receiver.team &&
              (deal.receiver.team.alternativeName
                ? deal.receiver.team.alternativeName
                : deal.receiver.team.name)}
          </div>
          <div style={{lineHeight: 1}}>{deal.receiver.username}</div>
        </div>
      </div>
      <div style={{width: '100%', display: 'flex', padding: '1.5vh 0 1vh'}}>
        <Icon type="import" style={{fontSize: '2rem', color: 'green'}} />
        <div style={{paddingLeft: '12px', fontSize: '1rem'}}>
          Jugadores libres
        </div>
      </div>
    </div>
  )
}

const TeamInfoFooterLayout = ({infoOnly, deal}) => {
  if (infoOnly) {
    switch (deal.type) {
      case 'AGREEMENT':
        return <TeamInfoFooterLayoutAgreement deal={deal} />
      case 'BOUGHT':
      case 'FREEPLAYER':
        return <TeamInfoFooterLayoutClause deal={deal} />
      default:
        return <TeamInfoFooterLayoutLayoff deal={deal} />
    }
  } else {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#b6d7e3',
          padding: '0 10px',
          minHeight: '32px'
        }}
      >
        <div>
          {deal.playersOffered && deal.playersOffered.length > 0 && (
            <Popover
              placement="bottom"
              content={
                <div>
                  {deal.playersOffered.map(player => (
                    <div
                      key={player.id}
                      style={{display: 'flex', alignItems: 'center'}}
                    >
                      <a
                        href={`http://pesdb.net/pes2021/?id=${player.publicId}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{fontWeight: 600}}
                      >
                        {player.name} - PT {player.rating}
                      </a>
                    </div>
                  ))}
                </div>
              }
            >
              <Button type="link" style={{paddingLeft: 0}}>
                Jugadores ofertados
              </Button>
            </Popover>
          )}
        </div>
        <div>
          {deal.moneyOffered && deal.moneyOffered > 0 && (
            <div style={{fontWeight: 600}}>{deal.moneyOffered} M</div>
          )}
        </div>
      </div>
    )
  }
}

const TeamInfoFooterLayoutAgreement = ({deal}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent:
          deal.playersOffered && deal.playersOffered.length > 0
            ? 'space-between'
            : 'initial',
        alignItems: 'center',
        background: '#b6d7e3',
        padding: '0 10px',
        minHeight: '32px',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px'
      }}
    >
      {deal.playersOffered && deal.playersOffered.length > 0 ? (
        <React.Fragment>
          <div>
            <Popover
              placement="bottom"
              content={
                <div>
                  {deal.playersOffered.map(player => (
                    <div
                      key={player.id}
                      style={{display: 'flex', alignItems: 'center'}}
                    >
                      <a
                        href={`http://pesdb.net/pes2021/?id=${player.publicId}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{fontWeight: 600}}
                      >
                        {player.name} - PT {player.rating}
                      </a>
                    </div>
                  ))}
                </div>
              }
            >
              <Button type="link" style={{paddingLeft: 0}}>
                ACUERDO DE INTERCAMBIO
              </Button>
            </Popover>
          </div>
          <div>
            {deal.moneyOffered && deal.moneyOffered > 0 && (
              <div style={{fontWeight: 600}}>{deal.moneyOffered} M</div>
            )}
          </div>
        </React.Fragment>
      ) : (
        <div>ACUERDO DE INTERCAMBIO</div>
      )}
    </div>
  )
}

const TeamInfoFooterLayoutClause = ({deal}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#b6d7e3',
        padding: '0 10px',
        minHeight: '32px',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px'
      }}
    >
      <React.Fragment>
        <div>{deal.receiver ? 'VENTA DIRECTA' : 'AGENTE LIBRE'}</div>
        <div style={{fontWeight: 600}}>{deal.moneyOffered} M</div>
      </React.Fragment>
    </div>
  )
}

const TeamInfoFooterLayoutLayoff = ({deal}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        background: '#b6d7e3',
        padding: '0 10px',
        minHeight: '32px',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px'
      }}
    >
      DESPIDO
    </div>
  )
}
