import {Col, Icon, Row, Typography} from 'antd'
import React, {useEffect, useState} from 'react'

import {GET_TEAM, GET_TEAM_CLAUSES_INFO} from '../queries'
import PlayerCard from '../../components/PlayerCard'
import groupBy from 'lodash/groupBy'
import positionTranslator from '../../helpers/positionTranslator'
import useCheckAgreementsEnabled from '../../hooks/useCheckAgreementsEnabled'
import useCheckChangeClausesEnabled from '../../hooks/useCheckChangeClausesEnabled'
import useCheckLayoffsEnabled from '../../hooks/useCheckLayoffsEnabled'
import useCheckPayClausesEnabled from '../../hooks/useCheckPayClausesEnabled'
import {useQuery} from '@apollo/client'

export default ({teamId, match}) => {
  const [team, setTeam] = useState(false)
  const [originalTeam, setOriginalTeam] = useState(false)
  const [clausesReceived, setClausesReceived] = useState(false)
  const [clausesPayed, setClausesPayed] = useState(false)
  const id = teamId ? teamId : match.params.id
  const payClausesEnabled = useCheckPayClausesEnabled()
  const agreementsEnabled = useCheckAgreementsEnabled()
  const changeClausesEnabled = useCheckChangeClausesEnabled()
  const layoffsEnabled = useCheckLayoffsEnabled()

  const {data, error, loading, refetch} = useQuery(GET_TEAM, {
    variables: {teamId: id},
    fetchPolicy: 'network-only'
  })

  const {
    data: clausesInfoData,
    error: clausesInfoError,
    loading: clausesInfoLoading
  } = useQuery(GET_TEAM_CLAUSES_INFO, {
    variables: {teamId: id},
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (data && data.team && !error) {
      const copiedData = Object.assign({}, data.team)
      const playersOrganized = groupBy(
        copiedData.players,
        item => item.position
      )

      const playersSorted = {}

      Object.keys(playersOrganized).forEach(position => {
        playersSorted[position] = playersOrganized[position].sort((a, b) => {
          return b.rating - a.rating
        })
      })

      const finalPlayerSet = {}

      if (playersSorted['GK'] && playersSorted['GK'].length > 0) {
        finalPlayerSet['GK'] = playersSorted['GK']
      }
      if (playersSorted['RB'] && playersSorted['RB'].length > 0) {
        finalPlayerSet['RB'] = playersSorted['RB']
      }
      if (playersSorted['LB'] && playersSorted['LB'].length > 0) {
        finalPlayerSet['LB'] = playersSorted['LB']
      }
      if (playersSorted['CB'] && playersSorted['CB'].length > 0) {
        finalPlayerSet['CB'] = playersSorted['CB']
      }
      if (playersSorted['DMF'] && playersSorted['DMF'].length > 0) {
        finalPlayerSet['DMF'] = playersSorted['DMF']
      }
      if (playersSorted['CMF'] && playersSorted['CMF'].length > 0) {
        finalPlayerSet['CMF'] = playersSorted['CMF']
      }
      if (playersSorted['AMF'] && playersSorted['AMF'].length > 0) {
        finalPlayerSet['AMF'] = playersSorted['AMF']
      }
      if (playersSorted['RMF'] && playersSorted['RMF'].length > 0) {
        finalPlayerSet['RMF'] = playersSorted['RMF']
      }
      if (playersSorted['RWF'] && playersSorted['RWF'].length > 0) {
        finalPlayerSet['RWF'] = playersSorted['RWF']
      }
      if (playersSorted['LMF'] && playersSorted['LMF'].length > 0) {
        finalPlayerSet['LMF'] = playersSorted['LMF']
      }
      if (playersSorted['LWF'] && playersSorted['LWF'].length > 0) {
        finalPlayerSet['LWF'] = playersSorted['LWF']
      }
      if (playersSorted['SS'] && playersSorted['SS'].length > 0) {
        finalPlayerSet['SS'] = playersSorted['SS']
      }
      if (playersSorted['CF'] && playersSorted['CF'].length > 0) {
        finalPlayerSet['CF'] = playersSorted['CF']
      }

      copiedData.players = finalPlayerSet

      setOriginalTeam(data.team)
      setTeam(copiedData)
    }
  }, [data, error])

  useEffect(() => {
    if (clausesInfoData && !clausesInfoError) {
      setClausesReceived(clausesInfoData.clausesInfo.clausesReceived)
      setClausesPayed(clausesInfoData.clausesInfo.clausesPayed)
    }
  }, [clausesInfoData, clausesInfoError])

  return (
    <div id="team-page">
      {loading && !team && (
        <div style={{textAlign: 'center'}}>
          <Icon type="sync" style={{fontSize: '2.5rem'}} spin />
        </div>
      )}

      {!loading && team && (
        <>
          <Row type="flex" align="middle" style={{marginBottom: '30px'}}>
            <Col span={12} xs={24}>
              <Typography.Title level={2} style={{marginBottom: 0}}>
                {team.alternativeName ? team.alternativeName : team.name}
              </Typography.Title>
              <Typography.Title
                level={4}
                style={{marginTop: 0, marginBottom: 0}}
              >
                {team.manager.username}
              </Typography.Title>
            </Col>
            <Col span={12} xs={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontWeight: 600,
                  fontSize: '.9rem'
                }}
              >
                <div style={{paddingRight: '8px', textAlign: 'left'}}>
                  <div>Jugadores</div>
                  <div>Presupuesto</div>
                  <div>Salario</div>
                  <div>Cláusulas recibidas</div>
                  <div>Cláusulas pagadas</div>
                </div>
                <div style={{paddingLeft: '8px', textAlign: 'right'}}>
                  <div>{originalTeam.players.length}</div>
                  <div>
                    {(
                      Math.round((originalTeam.budget + Number.EPSILON) * 100) /
                      100
                    ).toFixed(2)}{' '}
                    M
                  </div>
                  <div>
                    {originalTeam.players
                      .reduce(
                        (accumulator, currentPlayer) =>
                          accumulator +
                          Math.round(
                            (currentPlayer.clause + Number.EPSILON) * 10
                          ) /
                            100,
                        0
                      )
                      .toFixed(2) + ' M'}
                  </div>
                  <div>{clausesInfoLoading ? '...' : clausesReceived}</div>
                  <div>{clausesInfoLoading ? '...' : clausesPayed}</div>
                </div>
              </div>
            </Col>
          </Row>

          <div>
            {Object.keys(team.players).map(position => (
              <div key={position}>
                <div className="info-band">
                  {positionTranslator(position)
                    ? positionTranslator(position).plural.toUpperCase()
                    : position}
                </div>

                <Row type="flex" gutter={16}>
                  {team.players[position].map(player => (
                    <Col xs={8} md={4} key={player.id}>
                      <PlayerCard
                        player={player}
                        payClausesEnabled={payClausesEnabled}
                        agreementsEnabled={agreementsEnabled}
                        changeClausesEnabled={changeClausesEnabled}
                        layoffsEnabled={layoffsEnabled}
                        refetchCallback={refetch}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
