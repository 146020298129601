import {BUY_FREE_PLAYER, USER} from '../../Platform/queries'
import {Modal, Typography, notification} from 'antd'
import React, {useContext} from 'react'

import {UserContext} from '../../UserContext'
import {useMutation} from '@apollo/client'

export default ({visible, player, price, onOk, onCancel, refetchCallback}) => {
  const user = useContext(UserContext)
  const [buyFreePlayer, {loading}] = useMutation(BUY_FREE_PLAYER, {
    fetchPolicy: 'no-cache',
    refetchQueries: [{query: USER}]
  })

  const handleModal = async () => {
    if (price === 0) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description: 'El precio no es correcto. Consulta con el administrador.'
      })

      return
    }

    if (price > user.team.budget) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description:
          'Tu equipo no tiene suficiente presupuesto para comprar al jugador.'
      })

      return
    }

    try {
      await buyFreePlayer({
        variables: {
          playerId: player.id,
          price
        }
      })

      if (refetchCallback) {
        refetchCallback()
      }
    } catch (err) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description: err.graphQLErrors[0].message
      })
    }

    onOk()
  }

  return (
    <Modal
      title="Confirmación de compra"
      visible={visible}
      okText="Comprar"
      cancelText="Cancelar"
      onOk={handleModal}
      onCancel={onCancel}
      okButtonProps={{disabled: loading, loading}}
      cancelButtonProps={{disabled: loading}}
    >
      ¿Estás seguro de que quieres pagar{' '}
      <Typography.Text strong>{price} M</Typography.Text> por la transacción a
      cambio de {player.name}?
    </Modal>
  )
}
