import {Button, Modal, Popconfirm, notification} from 'antd'
import React, {useRef, useState} from 'react'

import AgreementForm from './AgreementForm'
import {CREATE_AGREEMENT} from '../../Platform/queries'
import {useMutation} from '@apollo/client'

export default ({visible, player, onOk, onCancel}) => {
  const formRef = useRef(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [createAgreement] = useMutation(CREATE_AGREEMENT, {
    fetchPolicy: 'no-cache'
  })

  const handleOk = () => {
    setIsSubmitting(true)
    formRef.current.validateFields(async (err, values) => {
      if (
        err ||
        (values.money === 0 && (!values.players || values.players.length === 0))
      ) {
        notification.error({
          message: '¡Ha ocurrido un error!',
          description: 'Necesitas ofrecer algún recurso para el acuerdo.'
        })
      }

      const mutationVariables = {
        playersPurchaseData: {
          requested: player.id
        }
      }

      if (values.money !== 0) {
        mutationVariables.moneyOffered = values.money
      }

      if (values.players && values.players.length > 0) {
        mutationVariables.playersPurchaseData.offered = values.players
      }

      const {data, error} = await createAgreement({
        variables: mutationVariables
      })

      if (data && !error) {
        notification.success({
          message: '¡Éxito!',
          description: 'Se ha enviado el acuerdo al Manager del jugador.'
        })
      }

      setIsSubmitting(false)
      onOk()
    })
  }

  return (
    <Modal
      title={`Acuerdo de compra de ${player.name}`}
      visible={visible}
      footer={
        <Popconfirm
          title="¿Seguro que quieres ofrecer el acuerdo?"
          onConfirm={handleOk}
          okText="Sí"
          cancelText="No"
        >
          <Button type="primary" loading={isSubmitting} disabled={isSubmitting}>
            Ofrecer acuerdo
          </Button>
        </Popconfirm>
      }
      onOk={handleOk}
      onCancel={onCancel}
    >
      <AgreementForm ref={formRef} />
    </Modal>
  )
}
