import {FIRE_PLAYER, USER} from '../../Platform/queries'
import {Modal, Typography, notification} from 'antd'

import React from 'react'
import {useMutation} from '@apollo/client'
import usePlayerLayoffPrice from '../../hooks/usePlayerLayoffPrice'

export default ({visible, player, onOk, onCancel, refetchCallback}) => {
  const layoffPrice = usePlayerLayoffPrice({player})

  const [firePlayer, {loading}] = useMutation(FIRE_PLAYER, {
    fetchPolicy: 'no-cache',
    refetchQueries: [{query: USER}]
  })

  const handleModal = async () => {
    try {
      await firePlayer({
        variables: {
          playerId: player.id
        }
      })

      if (refetchCallback) {
        refetchCallback()
      }
      onOk()
    } catch (err) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description: err.graphQLErrors[0].message
      })
    }
  }

  return (
    <Modal
      title="Confirmación de despido"
      visible={visible}
      okText="Despedir"
      cancelText="Cancelar"
      onOk={handleModal}
      onCancel={onCancel}
      okButtonProps={{disabled: loading, loading}}
      cancelButtonProps={{disabled: loading}}
    >
      ¿Estás seguro de que quieres despedir a{' '}
      <Typography.Text strong>{player.name}</Typography.Text> a cambio de{' '}
      <Typography.Text strong>{layoffPrice} M</Typography.Text>?
    </Modal>
  )
}
