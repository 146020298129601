import {gql} from 'apollo-boost'

export const USER = gql`
  query user {
    user {
      id
      username
      email
      role
      telegramNickname
      team {
        id
        name
        alternativeName
        budget
        boughtClauses
        players {
          id
          name
          rating
          publicId
          offerRequests {
            id
            type
            status
            createdAt
            moneyOffered
            buyer {
              id
              username
              team {
                id
                name
                alternativeName
                budget
                players {
                  id
                  name
                }
              }
            }
            playersOffered {
              id
              name
              publicId
              rating
            }
          }
        }
      }
    }
  }
`

export const GET_TEAMS = gql`
  query getTeams {
    teams: getTeams {
      id
      name
      alternativeName
      budget
      manager {
        id
      }
      players {
        id
        name
      }
    }
  }
`

export const GET_TEAM = gql`
  query getTeam($teamId: String!) {
    team: getTeam(teamId: $teamId) {
      id
      name
      alternativeName
      budget
      soldClauses
      players {
        id
        publicId
        name
        clause
        position
        rating
        purchasable
        team {
          id
          name
          alternativeName
          soldClauses
          players {
            id
            name
          }
        }
      }
      manager {
        id
        username
      }
    }
  }
`

export const GET_TEAM_CLAUSES_INFO = gql`
  query getTeamClausesInfo($teamId: String!) {
    clausesInfo: getTeamClausesInfo(teamId: $teamId) {
      clausesReceived
      clausesPayed
    }
  }
`

export const GET_TEAMS_WITH_MANAGERS = gql`
  query findTeamsWithManagers {
    teams: findTeamsWithManagers {
      id
      name
      alternativeName
      alternativeName
      budget
      receivedClauses
      payedClauses
      customReceivedClausesEnabled
      customPayedClausesEnabled
      manager {
        id
        email
        username
      }
      players {
        id
        name
        clause
      }
    }
  }
`

export const GET_PLAYERS = gql`
  query getPlayers {
    players: getPlayers {
      id
      publicId
      name
      rating
      position
      clause
      team {
        id
        name
        alternativeName
      }
    }
  }
`

export const GET_PLAYERS_WITH_TEAMS = gql`
  query findPlayersWithTeams {
    players: findPlayersWithTeams {
      id
      publicId
      name
      rating
      position
      clause
      team {
        id
        name
        alternativeName
      }
    }
  }
`

export const GET_PLAYERS_WITH_TEAMS_BY_NAME = gql`
  query findPlayersWithoutTeamsByName($name: String!) {
    players: findPlayersWithoutTeamsByName(name: $name) {
      id
      publicId
      name
      rating
      position
      clause
    }
  }
`

export const SET_USER_TEAM = gql`
  mutation setUserTeam($userId: String!, $teamId: String!) {
    setUserTeam(userId: $userId, teamId: $teamId)
  }
`

export const SET_PLAYER_TEAM = gql`
  mutation setPlayerTeam($playerId: String!, $teamId: String!) {
    setPlayerTeam(playerId: $playerId, teamId: $teamId)
  }
`

export const SET_PLAYER_CLAUSE = gql`
  mutation setPlayerClause($playerId: String!, $clause: Float!) {
    setPlayerClause(playerId: $playerId, clause: $clause)
  }
`

export const SETTINGS = gql`
  query settings {
    settings {
      data
    }
  }
`

export const CREATE_AGREEMENT = gql`
  mutation createAgreement($moneyOffered: Float, $playersPurchaseData: JSON) {
    createAgreement(
      agreementData: {
        moneyOffered: $moneyOffered
        playersPurchaseData: $playersPurchaseData
      }
    ) {
      id
      createdAt
      type
    }
  }
`

export const CREATE_PAY_CLAUSE_DEAL = gql`
  mutation createPayClauseAgreement($playerId: String!) {
    createPayClauseAgreement(playerId: $playerId) {
      id
      createdAt
      type
    }
  }
`

export const BUY_FREE_PLAYER = gql`
  mutation buyFreePlayer($playerId: String!) {
    buyFreePlayer(playerId: $playerId) {
      id
      createdAt
      type
    }
  }
`

export const FIRE_PLAYER = gql`
  mutation firePlayer($playerId: String!) {
    firePlayer(playerId: $playerId)
  }
`

export const ACCEPT_AGREEMENT = gql`
  mutation acceptAgreement($dealId: String!) {
    acceptAgreement(dealId: $dealId)
  }
`

export const CANCEL_AGREEMENT = gql`
  mutation cancelAgreement($dealId: String!) {
    cancelAgreement(dealId: $dealId)
  }
`

export const FIND_MARKETPLACE_PLAYERS = gql`
  query findMarketplacePlayers(
    $type: String!
    $page: Float!
    $search: String!
  ) {
    playerList: findMarketplacePlayers(
      type: $type
      page: $page
      search: $search
    ) {
      players {
        id
        publicId
        name
        clause
        position
        rating
        purchasable
        team {
          id
          name
          alternativeName
          soldClauses
          players {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`

export const GET_DEALS_ACTIVITY = gql`
  query getDealsActivity($page: Float!, $search: String!) {
    dealsList: getDealsActivity(page: $page, search: $search) {
      deals {
        id
        type
        status
        buyer {
          id
          username
          team {
            id
            name
            alternativeName
          }
        }
        playerRequested {
          id
          name
          rating
          publicId
          team {
            id
            name
            alternativeName
            manager {
              id
              username
            }
          }
        }
        playersOffered {
          id
          name
          publicId
          rating
        }
        receiver {
          id
          username
          team {
            id
            name
            alternativeName
          }
        }
        moneyOffered
        closedAt
      }
      totalCount
    }
  }
`

export const CREATE_PLAYER = gql`
  mutation createPlayer(
    $publicId: Float!
    $name: String!
    $rating: Float!
    $position: String!
    $officialTeam: String!
  ) {
    createPlayer(
      createPlayerData: {
        publicId: $publicId
        name: $name
        rating: $rating
        position: $position
        officialTeam: $officialTeam
      }
    ) {
      id
      name
    }
  }
`
