import {Button, Icon, Tabs, Typography} from 'antd'
import React, {useEffect, useState} from 'react'

import AddPlayerModal from './AddPlayerModal'
import PlayersList from './PlayersList'
import SettingsPanel from './SettingsPanel'
import TeamsList from './TeamsList'
import UsersList from './UsersList'

export default () => {
  const [activeTabKey, setActiveTabKey] = useState(1)
  const [showAddPlayers, setShowAddPlayers] = useState(false)
  const [showAddPlayersModal, setShowAddPlayersModal] = useState(false)

  useEffect(() => {
    if (activeTabKey === '3') {
      setShowAddPlayers(true)
    } else {
      setShowAddPlayers(false)
    }
  }, [activeTabKey])

  return (
    <div id="user-panel">
      <Typography.Title level={2}>Panel de administración</Typography.Title>

      <div className="info-band">OPCIONES</div>

      <Tabs
        defaultActiveKey="1"
        onChange={activeKey => setActiveTabKey(activeKey)}
        tabBarExtraContent={
          showAddPlayers ? (
            <Button
              type="primary"
              icon="plus"
              onClick={() => setShowAddPlayersModal(true)}
            >
              Añadir jugador
            </Button>
          ) : (
            undefined
          )
        }
      >
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="user" />
              <span className="admin-menu-label">Usuarios</span>
            </span>
          }
          key="1"
        >
          <UsersList activeTabKey={activeTabKey} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="team" />
              <span className="admin-menu-label">Equipos</span>
            </span>
          }
          key="2"
        >
          <TeamsList activeTabKey={activeTabKey} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="skin" />
              <span className="admin-menu-label">Jugadores</span>
            </span>
          }
          key="3"
        >
          <PlayersList activeTabKey={activeTabKey} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <Icon type="setting" />
              <span className="admin-menu-label">Ajustes</span>
            </span>
          }
          key="4"
        >
          <SettingsPanel activeTabKey={activeTabKey} />
        </Tabs.TabPane>
      </Tabs>

      <AddPlayerModal
        visible={showAddPlayersModal}
        onFinish={() => setShowAddPlayersModal(false)}
      />
    </div>
  )
}
