import React from 'react'

import {Icon} from 'antd'

export default () => {
  return (
    <div
      className="loading"
      style={{
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Icon type="sync" style={{fontSize: '2.5rem'}} spin />
    </div>
  )
}
