import React, {useEffect, useState} from 'react'

import {SETTINGS} from './Platform/queries'
import {useQuery} from '@apollo/client'

export const SettingsContext = React.createContext()

export default ({children}) => {
  const defaultContext = {
    settings: false,
    loaded: false
  }

  const [context, setContext] = useState(defaultContext)

  const {data, error} = useQuery(SETTINGS, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (data && data.settings && !error) {
      setContext({
        settings: data.settings.data,
        loaded: true
      })
    }
  }, [data, error])

  return (
    <SettingsContext.Provider value={context}>
      {children}
    </SettingsContext.Provider>
  )
}
