import React, {useEffect, useState} from 'react'
import {Table, Typography} from 'antd'

import {GET_TEAMS_WITH_MANAGERS} from '../queries'
import {Link} from 'react-router-dom'
import {useQuery} from '@apollo/client'

export default () => {
  const [teams, setTeams] = useState([])
  const {data, error, loading} = useQuery(GET_TEAMS_WITH_MANAGERS, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (data && data.teams && data.teams.length > 0 && !error) {
      data.teams.sort((a, b) => (a.name > b.name ? 1 : -1))
      setTeams(data.teams)
    }
  }, [data, error])

  const columns = [
    {
      title: 'Equipo',
      key: 'name',
      align: 'center',
      render: team => (
        <Link style={{color: '#6473af'}} to={`/equipo/${team.id}`}>
          {team.alternativeName ? team.alternativeName : team.name}
        </Link>
      )
    },
    {
      title: 'Presupuesto',
      align: 'center',
      render: team =>
        `${(Math.round((team.budget + Number.EPSILON) * 100) / 100).toFixed(
          2
        )} M`
    },
    {
      title: 'Jugadores',
      align: 'center',
      render: team => (team.players ? team.players.length : 0)
    },
    {
      title: 'Salario',
      align: 'center',
      render: team =>
        team.players
          .reduce(
            (accumulator, currentPlayer) =>
              accumulator +
              Math.round((currentPlayer.clause + Number.EPSILON) * 10) / 100,
            0
          )
          .toFixed(2) + ' M'
    },
    {
      title: 'Manager',
      align: 'center',
      render: team => team.manager.username
    }
  ]

  return (
    <div id="teams-page">
      <Typography.Title level={2}>Lista de equipos</Typography.Title>

      <div className="info-band">INFORMACIÓN</div>

      <Table
        pagination={false}
        size="middle"
        rowKey={'id'}
        loading={loading}
        dataSource={teams}
        columns={columns}
      />
    </div>
  )
}
