import {useContext, useEffect, useState} from 'react'

import {SettingsContext} from '../SettingsContext'

export default ({player}) => {
  const [price, setPrice] = useState(0)
  const settingsContext = useContext(SettingsContext)

  const getDefaultPricePerRating = rating => {
    let price = 0

    if (rating >= 86) {
      price = settingsContext.settings.freePlayers86HirePrice
    } else if (rating > 80) {
      price = settingsContext.settings.freePlayers85HirePrice
    } else if (rating >= 76) {
      price = settingsContext.settings.freePlayers80HirePrice
    } else if (rating > 70) {
      price = settingsContext.settings.freePlayers75HirePrice
    } else {
      price = settingsContext.settings.freePlayers70HirePrice
    }

    setPrice(price)
  }

  useEffect(() => {
    if (
      player &&
      player.rating &&
      settingsContext &&
      settingsContext.settings
    ) {
      getDefaultPricePerRating(player.rating)
    }
    // eslint-disable-next-line
  }, [settingsContext, player])

  return price
}
