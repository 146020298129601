import React, {useEffect, useState} from 'react'
import {cloneDeep, merge} from 'lodash'
import {useApolloClient, useMutation, useQuery} from '@apollo/client'

import {USER} from './Platform/queries'
import {gql} from 'apollo-boost'
import {notification} from 'antd'
import useInterval from './helpers/useInterval'

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`

const LOGOUT = gql`
  mutation logout {
    logOut
  }
`

export const UserContext = React.createContext()

export default ({children}) => {
  const apolloClient = useApolloClient()

  const setContext = data => {
    setUserContext(prevState => {
      const clonedPrevState = cloneDeep(prevState)
      const clonedData = cloneDeep(data)

      return merge(clonedPrevState, clonedData)
    })
  }

  const defaultUserContext = {
    logIn: async (username, password) => {
      try {
        await login({
          variables: {
            username,
            password
          }
        })

        setUserContext({
          ...userContext,
          isAuthenticated: true
        })

        const {data, error} = await refetch()

        if (data && data.user && !error) {
          setContext({
            ...data.user,
            loading: false,
            isAuthenticated: true
          })
        }
      } catch (err) {
        notification.error({
          message: '¡Ha ocurrido un error!',
          description: 'Las credenciales son incorrectas.'
        })
      }
    },

    signOut: async () => {
      await logout()
      setUserContext({
        ...defaultUserContext,
        loading: false
      })
      apolloClient.clearStore()
    },

    isAdmin: function () {
      return this.role && this.role === 'admin' ? true : false
    },

    isAuthenticated: false,
    loading: true
  }

  const [login] = useMutation(LOGIN, {
    fetchPolicy: 'no-cache'
  })
  const [logout] = useMutation(LOGOUT, {
    fetchPolicy: 'no-cache'
  })

  const [userContext, setUserContext] = useState(defaultUserContext)

  const {data, error, refetch} = useQuery(USER, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (data && data.user) {
      setContext({
        ...data.user,
        loading: false,
        isAuthenticated: true
      })
    } else {
      setContext({
        loading: false
      })
    }
  }, [data])

  useEffect(() => {
    if (error) {
      userContext.signOut()
    }
    // eslint-disable-next-line
  }, [error])

  useInterval(() => {
    if (userContext.isAuthenticated) {
      refetch()
    }
  }, 60 * 1000)

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  )
}
