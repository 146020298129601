import {Col, Layout, Row} from 'antd'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'

import Activity from './Activity'
import AdminPanel from './AdminPanel'
import Deals from './Deals'
import Header from './Header'
import Marketplace from './Marketplace'
import MyTeam from './MyTeam'
import React from 'react'
import Team from './Team'
import Teams from './Teams'
import UserPanel from './UserPanel'

export default () => {
  return (
    <Layout id="platform" style={{minHeight: '100vh'}}>
      <Router>
        <Header />
        <Layout.Content id="content">
          <Row id="content-row" type="flex">
            <Col
              xs={{span: 24, offset: 0}}
              md={{span: 14, offset: 5}}
              id="content-wrapper"
            >
              <Col xs={{span: 22, offset: 1}} md={{span: 20, offset: 2}}>
                <Switch>
                  <Route path="/mi-usuario" component={UserPanel} />
                  <Route path="/equipo/:id" component={Team} />
                  <Route exact path="/" component={Teams} />
                  <Route path="/mi-equipo" component={MyTeam} />
                  <Route path="/fichajes" component={Deals} />
                  <Route path="/mercado" component={Marketplace} />
                  <Route path="/actividad" component={Activity} />
                  <Route path="/admin" component={AdminPanel} />

                  <Redirect to="/" />
                </Switch>
              </Col>
            </Col>
          </Row>
          <div id="app-background" />
        </Layout.Content>
      </Router>
    </Layout>
  )
}
