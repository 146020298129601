// TODO: clear out this names with Salva
const positionsDictionary = {
  GK: {singular: 'Portero', plural: 'Porteros'},
  RMF: {singular: 'Extremo derecha', plural: 'Extremos derechos'},
  CB: {singular: 'Defensa central', plural: 'Defensas centrales'},
  DMF: {singular: 'Mediocentro', plural: 'Mediocentros'},
  CF: {singular: 'Delantero centro', plural: 'Delanteros centro'},
  LB: {singular: 'Lateral izquierda', plural: 'Laterales izquierda'},
  RB: {singular: 'Lateral derecho', plural: 'Laterales derecha'},
  AMF: {singular: 'Mediapunta', plural: 'Mediapuntas'},
  RWF: {singular: 'Delantero derecha', plural: 'Delanteros derecha'},
  SS: {singular: 'Segundo punta', plural: 'Segundos puntas'},
  LWF: {singular: 'Delantero izquierda', plural: 'Delanteros izquierda'},
  LMF: {
    singular: 'Extremo izquierda',
    plural: 'Extremos izquierdos'
  },
  CMF: {singular: 'Centrocampista', plural: 'Centrocampistas'}
}

export default position =>
  positionsDictionary[position] ? positionsDictionary[position] : false
