import {Button, Col, Row} from 'antd'
import {Link, Route, BrowserRouter as Router, Switch} from 'react-router-dom'

import AuthBg from '../assets/img/AuthBg.svg'
import React from 'react'
import SignIn from './SignIn'
import SignUp from './SignUp'
import logo from '../assets/img/logo.svg'

const GetStarted = () => {
  return (
    <div id="get-started">
      <div style={{marginBottom: '5vh'}}>
        <Button type="primary" style={{width: '100%'}}>
          <Link to="/iniciar-sesion">Iniciar sesión</Link>
        </Button>
      </div>
      <div>
        <Button type="danger" style={{width: '100%'}}>
          <Link to="/registrar">Registrarse</Link>
        </Button>
      </div>{' '}
    </div>
  )
}

export default () => {
  return (
    <div
      id="app-auth"
      style={{
        backgroundImage: `url(${AuthBg})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '100vh',
        textAlign: 'center'
      }}
    >
      <Row
        type="flex"
        justify="center"
        style={{height: '100%', paddingTop: '12vh'}}
      >
        <Col xs={20} md={8}>
          <img
            alt="logo"
            src={logo}
            style={{width: '24%', heigth: 'auto', marginBottom: '10vh'}}
          />
          <Router>
            <Switch>
              <Route path="/registrar" component={SignUp} />
              <Route path="/iniciar-sesion" component={SignIn} />
              <Route component={GetStarted} />
            </Switch>
          </Router>
        </Col>
      </Row>
    </div>
  )
}
