import {Card, Col, Icon, InputNumber, Row} from 'antd'
import React, {useContext, useEffect, useState} from 'react'
import {SET_PLAYER_CLAUSE, USER} from '../../Platform/queries'

import AgreementModal from '../AgreementModal'
import BuyFreeModal from '../BuyFreeModal'
import {ReactComponent as IconAcuerdos} from '../../assets/icons/IconAcuerdos.svg'
import LayoffModal from '../LayoffModal'
import PayClauseModal from '../PayClauseModal'
import {SettingsContext} from '../../SettingsContext'
import {UserContext} from '../../UserContext'
import {useMutation} from '@apollo/client'
import usePlayerDefaultClause from '../../hooks/usePlayerDefaultClause'

export default ({
  player,
  page,
  search,
  payClausesEnabled,
  agreementsEnabled,
  changeClausesEnabled,
  layoffsEnabled,
  refetchCallback
}) => {
  const [cardActions, setCardActions] = useState([])
  const user = useContext(UserContext)
  const settingsContext = useContext(SettingsContext)
  const [agreementModalVisible, setAgreementModalVisible] = useState(false)
  const [payClauseModalVisible, setPayClauseModalVisible] = useState(false)
  const [buyFreeModalVisible, setBuyFreeModalVisible] = useState(false)
  const [layoffModalVisible, setLayoffModalVisible] = useState(false)
  const [editClause, setEditClause] = useState(false)
  const [clauseValue, setClauseValue] = useState(player.clause)
  const [ballRatingImgUrl, setBallRatingImgUrl] = useState(
    'http://pesdb.net/pes2021/images/ball4.png'
  )
  const defaultClausePrice = usePlayerDefaultClause({player})
  const [setPlayerClause] = useMutation(SET_PLAYER_CLAUSE, {
    fetchPolicy: 'no-cache',
    refetchQueries: [{query: USER}]
  })

  const hideAgreementModal = () => {
    setAgreementModalVisible(false)
  }

  const hidePayClauseModal = () => {
    setPayClauseModalVisible(false)
  }

  const hideBuyFreeModal = () => {
    setBuyFreeModalVisible(false)
  }

  const hideLayoffModal = () => {
    setLayoffModalVisible(false)
  }

  const handleClauseChange = async () => {
    try {
      await setPlayerClause({
        variables: {
          playerId: player.id,
          clause: clauseValue
        }
      })
      await refetchCallback()
    } catch (err) {}

    setEditClause(false)
  }

  useEffect(() => {
    let img = null

    if (player.rating >= 85) {
      img = 'http://pesdb.net/pes2021/images/ball4.png'
    } else if (player.rating >= 80) {
      img = 'http://pesdb.net/pes2021/images/ball3.png'
    } else if (player.rating >= 75) {
      img = 'http://pesdb.net/pes2021/images/ball2.png'
    } else if (player.rating >= 70) {
      img = 'http://pesdb.net/pes2021/images/ball1.png'
    } else {
      img = 'http://pesdb.net/pes2021/images/ball0.png'
    }

    setBallRatingImgUrl(img)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const actions = []

    if (player.team) {
      if (player.team.id === user.team.id) {
        if (changeClausesEnabled) {
          actions.push(
            <Icon type={'edit'} onClick={() => setEditClause(true)} />
          )
        }

        if (
          layoffsEnabled &&
          player.team.players &&
          player.team.players.length >= 16
        ) {
          actions.push(
            <Icon type={'delete'} onClick={() => setLayoffModalVisible(true)} />
          )
        }
      } else {
        if (
          agreementsEnabled &&
          player.team.players &&
          user.team.players.length <= 25 &&
          player.team.players.length >= 16
        ) {
          actions.push(
            <Icon
              style={{fontSize: '1.25rem', color: 'black'}}
              component={IconAcuerdos}
              onClick={() => setAgreementModalVisible(true)}
            />
          )
        }

        if (
          payClausesEnabled &&
          user.team &&
          user.team.players &&
          user.team.players.length <= 25 &&
          player.team.players &&
          player.team.players.length >= 16 &&
          player.purchasable === 1 &&
          player.team.soldClauses <= settingsContext.settings.maxSoldClauses
        ) {
          actions.push(
            <Icon
              style={{color: '#434044'}}
              type="shopping-cart"
              onClick={() => setPayClauseModalVisible(true)}
            />
          )
        }
      }
    }

    if (!player.team && user.team.players.length <= 25) {
      actions.push(
        <Icon
          style={{color: '#434044'}}
          type="shopping-cart"
          onClick={() => setBuyFreeModalVisible(true)}
        />
      )
    }

    setCardActions(actions)
    // eslint-disable-next-line
  }, [
    payClausesEnabled,
    agreementsEnabled,
    changeClausesEnabled,
    layoffsEnabled,
    page,
    search
  ])

  return (
    <div className="player-card-wrapper">
      <Card
        bordered
        bodyStyle={{padding: 0}}
        actions={cardActions}
        className="player-card"
      >
        <div style={{textAlign: 'center'}}>
          <img
            src={`http://pesdb.net/pes2021/images/players/${player.publicId}.png`}
            alt={''}
            style={{maxWidth: '100%', height: 'auto'}}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            padding: '1px 0',
            backgroundColor: '#b6d7e3'
          }}
        >
          <a
            href={`https://pesdb.net/pes2022/?mode=authentic&id=${player.publicId}`}
            rel="noopener noreferrer"
            target="_blank"
            style={{color: 'rgba(0, 0, 0, 0.85)'}}
          >
            {player.name}
          </a>
        </div>
        <Row
          type="flex"
          style={{paddingTop: '3px', paddingBottom: '3px'}}
          align="middle"
        >
          {!editClause && (
            <React.Fragment>
              <Col span={5} style={{textAlign: 'center'}}>
                PT
                <br />
                {player.rating}
              </Col>
              <Col span={14} style={{textAlign: 'center'}}>
                {`${
                  player.clause !== 0 ? player.clause : defaultClausePrice
                } M`}
              </Col>
              <Col span={5} style={{textAlign: 'center', paddingRight: '3px'}}>
                <img
                  src={ballRatingImgUrl}
                  alt="Balón"
                  style={{maxWidth: '100%'}}
                />
              </Col>
            </React.Fragment>
          )}

          {editClause && (
            <Col
              span={24}
              style={{
                textAlign: 'center',
                padding: '3px 0 3px 5px'
              }}
            >
              <div style={{display: 'flex', alignItems: 'center'}}>
                <InputNumber
                  style={{width: '80%'}}
                  defaultValue={player.clause}
                  formatter={value => `${value} M`}
                  parser={value => value.replace(' M', '')}
                  onChange={value => setClauseValue(value)}
                />
                <div>
                  <Icon type="check" onClick={handleClauseChange} />
                  <Icon type="close" onClick={() => setEditClause(false)} />
                </div>
              </div>
            </Col>
          )}
        </Row>

        {agreementsEnabled && (
          <AgreementModal
            visible={agreementModalVisible}
            player={player}
            onCancel={hideAgreementModal}
            onOk={hideAgreementModal}
          />
        )}

        {payClausesEnabled && (
          <PayClauseModal
            visible={payClauseModalVisible}
            player={player}
            price={defaultClausePrice}
            onOk={hidePayClauseModal}
            onCancel={hidePayClauseModal}
            refetchCallback={refetchCallback}
          />
        )}

        {layoffsEnabled && (
          <LayoffModal
            visible={layoffModalVisible}
            player={player}
            onCancel={hideLayoffModal}
            onOk={hideLayoffModal}
            refetchCallback={refetchCallback}
          />
        )}

        <BuyFreeModal
          visible={buyFreeModalVisible}
          player={player}
          price={defaultClausePrice}
          onCancel={hideBuyFreeModal}
          onOk={hideBuyFreeModal}
          refetchCallback={refetchCallback}
        />
      </Card>
    </div>
  )
}
