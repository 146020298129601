import {Button, Form, Input, Typography, notification} from 'antd'
import React, {useContext, useEffect, useState} from 'react'

import {UserContext} from '../UserContext'
import formHasErrors from '../helpers/formHasErrors'
import {withRouter} from 'react-router-dom'

const SignIn = ({form, history}) => {
  const {
    getFieldDecorator,
    validateFields,
    isFieldTouched,
    getFieldError,
    getFieldsError
  } = form

  const user = useContext(UserContext)

  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    validateFields(async (errors, values) => {
      if (!errors) {
        const loginResponse = await user.logIn(values.username, values.password)

        if (loginResponse) {
          notification.success({
            message: '¡Éxito!',
            description: 'Has iniciado sesión correctamente.'
          })
          history.push('/')
        }
      }
    })
    setSubmitting(false)
  }

  useEffect(() => {
    validateFields()
  }, [validateFields])

  const usernameError = isFieldTouched('username') && getFieldError('username')
  const passwordError = isFieldTouched('password') && getFieldError('password')

  return (
    <Form onSubmit={handleSubmit}>
      <Typography.Title style={{marginBottom: '3vh'}} level={3}>
        Inicia sesión
      </Typography.Title>

      <Form.Item
        label={'Nombre de usuario'}
        validateStatus={usernameError ? 'error' : ''}
        help={usernameError ? usernameError : ''}
      >
        {getFieldDecorator('username', {
          rules: [
            {
              required: true,
              message: '¡Necesitas indicar tu nombre de usuario!'
            }
          ]
        })(<Input placeholder="Nombre de usuario" autoFocus />)}
      </Form.Item>

      <Form.Item
        label={'Contraseña'}
        validateStatus={passwordError ? 'error' : ''}
        help={passwordError ? passwordError : ''}
      >
        {getFieldDecorator('password', {
          rules: [
            {required: true, message: '¡Necesitas indicar tu contraseña!'}
          ]
        })(<Input.Password placeholder="Contraseña" />)}
      </Form.Item>

      <Form.Item>
        <Button
          loading={submitting}
          disabled={formHasErrors(getFieldsError()) || submitting}
          htmlType="submit"
          type="primary"
        >
          Iniciar sesión
        </Button>
      </Form.Item>
    </Form>
  )
}

const SignInForm = Form.create({name: 'SignIn'})(withRouter(SignIn))

export default () => {
  return (
    <div id="sign-in" style={{textAlign: 'center'}}>
      <SignInForm />
    </div>
  )
}
