import {Col, Empty, Row, Tabs, Typography} from 'antd'
import React, {useContext, useEffect, useState} from 'react'

import DealCard from '../../components/DealCard'
import {UserContext} from '../../UserContext'

export default () => {
  const user = useContext(UserContext)
  const [agreements, setAgreements] = useState([])

  useEffect(() => {
    const deals = {active: [], past: []}

    if (
      user &&
      user.team &&
      user.team.players &&
      user.team.players.length > 0
    ) {
      user.team.players.forEach(player =>
        player.offerRequests.forEach(deal => {
          if (deal.type === 'AGREEMENT' && deal.status === 'PENDING') {
            deals.active.push({
              ...deal,
              playerRequested: {
                name: player.name,
                rating: player.rating,
                publicId: player.publicId
              }
            })
          } else if (
            deal.type === 'AGREEMENT' &&
            (deal.status === 'REJECTED' || deal.status === 'ACCEPTED') &&
            deal.buyer.id !== user.id
          ) {
            deals.past.push({
              ...deal,
              playerRequested: {
                name: player.name,
                rating: player.rating,
                publicId: player.publicId
              }
            })
          }
        })
      )
    }
    deals.active.sort((a, b) => b.createdAt - a.createdAt)
    deals.past.sort((a, b) => b.createdAt - a.createdAt)

    setAgreements(deals)
    // eslint-disable-next-line
  }, [user])

  return (
    <div id="agreements-page">
      <Typography.Title level={2}>Fichajes</Typography.Title>

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={<span>Activos</span>} key="1">
          {agreements && agreements.active && agreements.active.length > 0 ? (
            <div style={{padding: '1.5vh .5vw'}}>
              <Row type="flex" gutter={16}>
                {agreements.active.map(agreement => (
                  <Col
                    key={agreement.id}
                    xs={24}
                    md={8}
                    style={{marginBottom: '20px'}}
                  >
                    <DealCard deal={agreement} />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <Empty description={<span>No hay datos</span>} />
          )}
        </Tabs.TabPane>

        <Tabs.TabPane tab={<span>Finalizados</span>} key="2">
          {agreements && agreements.past && agreements.past.length > 0 ? (
            <div style={{padding: '1.5vh .5vw'}}>
              <Row type="flex" gutter={16}>
                {agreements.past.map(agreement => (
                  <Col
                    key={agreement.id}
                    xs={24}
                    md={8}
                    style={{marginBottom: '20px'}}
                  >
                    <DealCard pastDeal deal={agreement} />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <Empty description={<span>No hay datos</span>} />
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
