import {Button, Form, Input, Typography, notification} from 'antd'
import React, {useContext, useEffect, useState} from 'react'

import {UserContext} from '../../UserContext'
import {gql} from 'apollo-boost'
import {useMutation} from '@apollo/client'

const UPDATE_PASSWORD = gql`
  mutation updatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(
      updatePasswordData: {
        currentPassword: $currentPassword
        newPassword: $newPassword
      }
    ) {
      id
      username
    }
  }
`

const UPDATE_TELEGRAM_NICKNAME = gql`
  mutation updateTelegramNickname($nickname: String!) {
    updateTelegramNickname(nickname: $nickname)
  }
`

const UPDATE_TEAM_ALTERNATIVE_NAME = gql`
  mutation setTeamAlternativeName($teamId: String!, $alternativeName: String!) {
    setTeamAlternativeName(teamId: $teamId, alternativeName: $alternativeName)
  }
`

const UserPanel = ({form}) => {
  const user = useContext(UserContext)

  const {
    getFieldDecorator,
    validateFields,
    getFieldError,
    getFieldsError,
    isFieldTouched,
    resetFields
  } = form

  const [submitting, setSubmitting] = useState(false)
  const [telegramNickname, setTelegramNickname] = useState(false)
  const [teamAlternativeName, setTeamAlternativeName] = useState(false)
  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    fetchPolicy: 'no-cache'
  })
  const [
    updateTelegramNickname,
    {loading: telegramNicknameLoading}
  ] = useMutation(UPDATE_TELEGRAM_NICKNAME, {
    fetchPolicy: 'no-cache'
  })
  const [
    updateTeamAlternativeName,
    {loading: teamAlternativeNameLoading}
  ] = useMutation(UPDATE_TEAM_ALTERNATIVE_NAME, {
    fetchPolicy: 'no-cache'
  })

  const hasErrors = () => {
    const fieldsErrors = getFieldsError()
    return Object.keys(fieldsErrors).some(elem => fieldsErrors[elem])
  }

  const handleSubmit = e => {
    e.preventDefault()

    validateFields(async (error, values) => {
      if (!error) {
        if (values.currentPassword === values.newPassword) {
          notification.error({
            message: '¡Ha ocurrido un error!',
            description:
              'Tu nueva contraseña no puede ser la misma que la actual.'
          })
        } else if (values.newPassword !== values.repeatPassword) {
          notification.error({
            message: '¡Ha ocurrido un error!',
            description:
              'Las dos nuevas contraseñas que has indicado no coinciden.'
          })
        } else {
          setSubmitting(true)

          try {
            const response = await updatePassword({
              variables: {
                currentPassword: values.currentPassword,
                newPassword: values.newPassword
              }
            })

            if (response && response.data && response.data.updatePassword) {
              notification.success({
                message: '¡Éxito!',
                description: 'Tu contraseña se actualizó correctamente.'
              })
            }

            resetFields()
          } catch (err) {
            notification.error({
              message: '¡Ha ocurrido un error!',
              description:
                'La contraseña actual que has indicado no es correcta.'
            })
          }

          setSubmitting(false)
        }
      }
    })
  }

  const handleTelegramUpdate = async () => {
    await updateTelegramNickname({
      variables: {
        nickname: telegramNickname
      }
    })

    notification.success({
      message: '¡Éxito!',
      description: 'Tu alias de Telegram se actualizó correctamente.'
    })
  }

  const handleTeamAlternativeNameUpdate = async () => {
    await updateTeamAlternativeName({
      variables: {
        teamId: user.team.id,
        alternativeName: teamAlternativeName
      }
    })

    notification.success({
      message: '¡Éxito!',
      description:
        'El nombre alternativo de tu equipo se ha actualizado correctamente.'
    })
  }

  useEffect(() => {
    validateFields()
  }, [validateFields])

  const currentPasswordError =
    isFieldTouched('currentPassword') && getFieldError('currentPassword')
  const newPasswordError =
    isFieldTouched('newPassword') && getFieldError('newPassword')
  const repeatPasswordError =
    isFieldTouched('repeatPassword') && getFieldError('repeatPassword')

  return (
    <div id="user-panel">
      <Typography.Title level={2}>Editar perfil</Typography.Title>

      <div className="info-band">DATOS PERSONALES</div>

      <Form onSubmit={handleSubmit}>
        <Form.Item label="Correo electrónico">
          <Input disabled defaultValue={user.email} />
        </Form.Item>

        <Form.Item
          label="Contraseña actual"
          validateStatus={currentPasswordError ? 'error' : ''}
          help={currentPasswordError || ''}
        >
          {getFieldDecorator('currentPassword', {
            rules: [
              {
                required: true,
                message: '¡Necesitas indicar tu contraseña actual!'
              }
            ]
          })(<Input.Password placeholder="Contraseña actual" />)}
        </Form.Item>

        <Form.Item
          label="Nueva contraseña"
          validateStatus={newPasswordError ? 'error' : ''}
          help={newPasswordError || ''}
        >
          {getFieldDecorator('newPassword', {
            rules: [
              {
                required: true,
                message: '¡Necesitas indicar tu nueva contraseña!'
              }
            ]
          })(<Input.Password placeholder="Nueva contraseña" />)}
        </Form.Item>

        <Form.Item
          label="Repetir contraseña"
          validateStatus={repeatPasswordError ? 'error' : ''}
          help={repeatPasswordError || ''}
        >
          {getFieldDecorator('repeatPassword', {
            rules: [
              {
                required: true,
                message: '¡Necesitas verificar tu contraseña!'
              }
            ]
          })(<Input.Password placeholder="Repetir contraseña" />)}
        </Form.Item>

        <Form.Item>
          <Button
            loading={submitting}
            disabled={hasErrors() || submitting}
            htmlType="submit"
            type="primary"
          >
            Guardar
          </Button>
        </Form.Item>
      </Form>

      <div className="info-band" style={{marginTop: '5vh'}}>
        NOMBRE ALTERNATIVO DEL EQUIPO
      </div>

      <Form.Item
        label="Nombre alternativo del equipo"
        help={
          <span>
            Este será el nombre mostrado en las diferentes secciones. Por favor,
            procura que no sea demasiado largo.
          </span>
        }
      >
        <Input
          defaultValue={user?.team?.alternativeName}
          onChange={e => setTeamAlternativeName(e.target.value)}
          placeholder="Nombre alternativo del equipo"
        />
      </Form.Item>

      <Form.Item>
        <Button
          loading={teamAlternativeNameLoading}
          disabled={!teamAlternativeName || teamAlternativeNameLoading}
          type="primary"
          onClick={handleTeamAlternativeNameUpdate}
        >
          Guardar
        </Button>
      </Form.Item>

      <div className="info-band" style={{marginTop: '5vh'}}>
        CUENTA DE TELEGRAM
      </div>

      <Form.Item
        label="Alias de Telegram"
        help={
          <span>
            Para suscribirte al bot accede a{' '}
            <a
              rel="noopener noreferrer"
              href="http://t.me/LigaParretoBot"
              target="_blank"
            >
              http://t.me/LigaParretoBot
            </a>
          </span>
        }
      >
        <Input
          defaultValue={user.telegramNickname}
          onChange={e => setTelegramNickname(e.target.value)}
          placeholder="Alias de Telegram"
        />
      </Form.Item>

      <Form.Item>
        <Button
          loading={telegramNicknameLoading}
          disabled={!telegramNickname || telegramNicknameLoading}
          type="primary"
          onClick={handleTelegramUpdate}
        >
          Guardar
        </Button>
      </Form.Item>
    </div>
  )
}

export default Form.create({name: 'UserPanel'})(UserPanel)
