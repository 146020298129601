import {Button, Form, Input, Typography, notification} from 'antd'
import React, {useEffect, useState} from 'react'

import formHasErrors from '../helpers/formHasErrors'
import {gql} from 'apollo-boost'
import {useMutation} from '@apollo/client'
import {withRouter} from 'react-router-dom'

const SIGN_UP = gql`
  mutation signUp(
    $username: String!
    $email: String!
    $password: String!
    $code: String!
  ) {
    signUp(
      signUpData: {
        username: $username
        email: $email
        password: $password
        code: $code
      }
    ) {
      id
      username
    }
  }
`

const SignUp = ({form, history}) => {
  const {
    getFieldDecorator,
    validateFields,
    isFieldTouched,
    getFieldError,
    getFieldsError
  } = form

  const [signUp] = useMutation(SIGN_UP, {
    fetchPolicy: 'no-cache'
  })
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true)
    validateFields(async (errors, values) => {
      if (!errors) {
        try {
          const response = await signUp({
            variables: {
              username: values.username,
              email: values.email,
              password: values.password,
              code: values.code
            }
          })

          if (response && response.data && response.data.signUp) {
            notification.success({
              message: '¡Éxito!',
              description:
                'El usuario se ha creado correctamente. Ahora puedes iniciar sesión.'
            })

            history.push('/')
          }
        } catch (err) {
          switch (err.message) {
            case 'GraphQL error: Wrong referal code!':
              notification.error({
                message: '¡Ha ocurrido un error!',
                description: 'El código de referencia no es correcto.'
              })
              break
            case 'GraphQL error: That email is already in the database!':
              notification.error({
                message: '¡Ha ocurrido un error!',
                description:
                  'Ese nombre de usuario o email ya está en uso en la aplicación.'
              })
              break
            default:
          }
        }
      }
    })
    setSubmitting(false)
  }

  useEffect(() => {
    validateFields()
  }, [validateFields])

  const usernameError = isFieldTouched('username') && getFieldError('username')
  const emailError = isFieldTouched('email') && getFieldError('email')
  const passwordError = isFieldTouched('password') && getFieldError('password')
  const codeError = isFieldTouched('code') && getFieldError('code')

  return (
    <Form onSubmit={handleSubmit}>
      <Typography.Title style={{marginBottom: '3vh'}} level={3}>
        Registrar cuenta
      </Typography.Title>

      <Form.Item
        label={'Nombre de usuario'}
        validateStatus={usernameError ? 'error' : ''}
        help={usernameError ? usernameError : ''}
      >
        {getFieldDecorator('username', {
          rules: [
            {
              required: true,
              message: '¡Necesitas indicar tu nombre de usuario!'
            }
          ]
        })(<Input placeholder="Nombre de usuario" />)}
      </Form.Item>

      <Form.Item
        label={'Correo electrónico'}
        validateStatus={emailError ? 'error' : ''}
        help={emailError ? emailError : ''}
      >
        {getFieldDecorator('email', {
          rules: [
            {
              required: true,
              message: '¡Necesitas indicar tu correo electrónico!'
            },
            {
              type: 'email',
              message: '¡El formato del correo electrónico es incorrecto!'
            }
          ]
        })(<Input placeholder="Correo electrónico" />)}
      </Form.Item>

      <Form.Item
        label={'Contraseña'}
        validateStatus={passwordError ? 'error' : ''}
        help={passwordError ? passwordError : ''}
      >
        {getFieldDecorator('password', {
          rules: [
            {required: true, message: '¡Necesitas indicar tu contraseña!'}
          ]
        })(<Input.Password placeholder="Contraseña" />)}
      </Form.Item>

      <Form.Item
        label={'Código de invitación'}
        validateStatus={codeError ? 'error' : ''}
        help={codeError ? codeError : ''}
      >
        {getFieldDecorator('code', {
          rules: [
            {
              required: true,
              message: '¡Necesitas indicar tu código de invitación!'
            }
          ]
        })(<Input placeholder="Código de invitación" />)}
      </Form.Item>

      <Form.Item>
        <Button
          loading={submitting}
          disabled={formHasErrors(getFieldsError()) || submitting}
          htmlType="submit"
          type="primary"
        >
          Registrarse
        </Button>
      </Form.Item>
    </Form>
  )
}

const SignUpForm = Form.create({name: 'SignUp'})(withRouter(SignUp))

export default () => {
  return (
    <div id="sign-up" style={{textAlign: 'center'}}>
      <SignUpForm />
    </div>
  )
}
