import {Icon, List} from 'antd'
import React, {useEffect, useState} from 'react'

import {FIND_MARKETPLACE_PLAYERS} from '../../queries'
import PlayerCard from '../../../components/PlayerCard'
import useCheckAgreementsEnabled from '../../../hooks/useCheckAgreementsEnabled'
import useCheckChangeClausesEnabled from '../../../hooks/useCheckChangeClausesEnabled'
import useCheckLayoffsEnabled from '../../../hooks/useCheckLayoffsEnabled'
import useCheckPayClausesEnabled from '../../../hooks/useCheckPayClausesEnabled'
import {useQuery} from '@apollo/client'

export default ({search}) => {
  const [players, setPlayers] = useState([])
  const [page, setPage] = useState(1)
  const [totalPlayers, setTotalPlayers] = useState(1)
  const payClausesEnabled = useCheckPayClausesEnabled()
  const agreementsEnabled = useCheckAgreementsEnabled()
  const changeClausesEnabled = useCheckChangeClausesEnabled()
  const layoffsEnabled = useCheckLayoffsEnabled()

  const {data, error, loading, refetch} = useQuery(FIND_MARKETPLACE_PLAYERS, {
    variables: {
      type: 'all',
      page,
      search
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (data && data.playerList && !error) {
      setPlayers(data.playerList.players)
      setTotalPlayers(data.playerList.totalCount)
    }
  }, [data, error])

  useEffect(() => {
    if (data) {
      refetch()
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    setPage(1)
    refetch({
      type: 'all',
      page: 1,
      search
    })
    // eslint-disable-next-line
  }, [search])

  return (
    <div id="marketplace-all-players">
      {loading && (
        <div style={{textAlign: 'center'}}>
          <Icon type="sync" style={{fontSize: '2.5rem'}} spin />
        </div>
      )}

      {!loading && (
        <List
          grid={{
            gutter: 16,
            md: 6,
            xs: 3
          }}
          pagination={{
            pageSize: 18,
            onChange: value => setPage(value),
            current: page,
            total: totalPlayers,
            size: 'small'
          }}
          dataSource={players}
          rowKey={'id'}
          renderItem={player => (
            <List.Item>
              <PlayerCard
                player={player}
                page={page}
                search={search}
                payClausesEnabled={payClausesEnabled}
                agreementsEnabled={agreementsEnabled}
                changeClausesEnabled={changeClausesEnabled}
                layoffsEnabled={layoffsEnabled}
                refetchCallback={refetch}
              />
            </List.Item>
          )}
        />
      )}
    </div>
  )
}
