import {Modal, Typography, notification} from 'antd'
import React, {useRef} from 'react'

import AddPlayerForm from './AddPlayerForm'
import {CREATE_PLAYER} from '../../queries'
import {useMutation} from '@apollo/client'

export default ({visible, onFinish}) => {
  const formRef = useRef(null)
  const [createPlayer, {loading}] = useMutation(CREATE_PLAYER, {
    fetchPolicy: 'no-cache'
  })

  const handleOk = () => {
    formRef.current.validateFields(async (err, values) => {
      if (err) {
        return
      }

      const data = {
        publicId: parseInt(values.publicId),
        name: values.name.toUpperCase(),
        rating: parseInt(values.rating),
        position: values.position,
        officialTeam: values.officialTeam.toUpperCase()
      }

      try {
        await createPlayer({
          variables: data
        })
      } catch (err) {
        notification.error({
          message: '¡Ha ocurrido un error!',
          description: err.graphQLErrors[0].message
        })
      }

      formRef.current.resetFields()
      onFinish()
    })
  }

  return (
    <Modal
      title="Añadir jugador manualmente"
      visible={visible}
      okText="Añadir"
      cancelText="Cancelar"
      onCancel={onFinish}
      onOk={handleOk}
      cancelButtonProps={{disabled: loading}}
      okButtonProps={{disabled: loading, loading}}
    >
      <div>
        <Typography.Text strong>NOTA: </Typography.Text>
        Recuerda buscar primero el jugador que quieres añadir en{' '}
        <a
          href="http://pesdb.net/pes2021/"
          target="_blank"
          rel="noopener noreferrer"
        >
          PesDB
        </a>{' '}
        para copiar la información.
      </div>

      <AddPlayerForm ref={formRef} />
    </Modal>
  )
}
