import {Col, Icon, Input, List, Row, Typography} from 'antd'
import React, {useEffect, useState} from 'react'

import DealCard from '../../components/DealCard'
import {GET_DEALS_ACTIVITY} from '../queries'
import {useQuery} from '@apollo/client'

export default () => {
  const [deals, setDeals] = useState([])
  const [page, setPage] = useState(1)
  const [totalDeals, setTotalDeals] = useState(1)
  const [search, setSearch] = useState('')

  const {data, error, loading, refetch} = useQuery(GET_DEALS_ACTIVITY, {
    variables: {
      page,
      search
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (data) {
      refetch()
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    setPage(1)
    refetch({
      page: 1,
      search
    })
    // eslint-disable-next-line
  }, [search])

  useEffect(() => {
    if (data && data.dealsList && !error) {
      const dealsData = data.dealsList.deals
      dealsData.sort((a, b) => b.closedAt - a.closedAt)
      setDeals(dealsData)
      setTotalDeals(data.dealsList.totalCount)
    }
  }, [data, error])

  return (
    <div id="activity-page">
      <Typography.Title level={2}>Actividad</Typography.Title>

      <div className="info-band">ACUERDOS</div>

      {loading && (
        <div style={{textAlign: 'center'}}>
          <Icon type="sync" style={{fontSize: '2.5rem'}} spin />
        </div>
      )}

      {!loading && (
        <React.Fragment>
          <Row style={{textAlign: 'right', marginBottom: '3vh'}}>
            <Col md={{span: 8, offset: 16}}>
              <Input.Search
                placeholder="Filtrar por jugador"
                onChange={e => setSearch(e.target.value)}
              />
            </Col>
          </Row>
          <List
            grid={{
              gutter: 16,
              md: 3,
              xs: 1
            }}
            pagination={{
              pageSize: 18,
              onChange: value => setPage(value),
              current: page,
              total: totalDeals,
              size: 'small'
            }}
            rowKey={'id'}
            dataSource={deals}
            renderItem={deal => (
              <List.Item>
                <DealCard infoOnly deal={deal} />
              </List.Item>
            )}
          />
        </React.Fragment>
      )}
    </div>
  )
}
