import {useContext, useEffect, useState} from 'react'

import {SettingsContext} from '../SettingsContext'

export default () => {
  const [enabled, setEnabled] = useState(false)
  const settingsContext = useContext(SettingsContext)

  useEffect(() => {
    const now = Math.floor(Date.now() / 1000)

    if (
      settingsContext &&
      settingsContext.settings.makeDealsPeriodStart &&
      settingsContext.settings.makeDealsPeriodEnd &&
      settingsContext.settings.makeDealsPeriodStart <= now &&
      settingsContext.settings.makeDealsPeriodEnd >= now
    ) {
      setEnabled(true)
    }
  }, [settingsContext])

  return enabled
}
