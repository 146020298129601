import {Input, Tabs, Typography} from 'antd'
import React, {useState} from 'react'

import AllPlayers from './AllPlayers'
import FreePlayers from './FreePlayers'

export default () => {
  const [search, setSearch] = useState('')

  return (
    <div id="marketplace-page">
      <Typography.Title level={2}>Mercado</Typography.Title>

      <div className="info-band">JUGADORES</div>

      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={
          <Input.Search
            placeholder="Filtra por el jugador"
            onChange={e => setSearch(e.target.value)}
          />
        }
      >
        <Tabs.TabPane tab={<span>Jugadores libres</span>} key="1">
          <div style={{padding: '1.5vh .5vw'}}>
            <FreePlayers search={search} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span>Todos los jugadores</span>} key="2">
          <div style={{padding: '1.5vh .5vw'}}>
            <AllPlayers search={search} />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
