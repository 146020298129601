import 'antd/dist/antd.css'

import ApolloClient from 'apollo-boost'
import {ApolloProvider} from '@apollo/client'
import App from './App'
import {ConfigProvider} from 'antd'
import React from 'react'
import ReactDOM from 'react-dom'
import SettingsContextProvider from './SettingsContext'
import UserContextProvider from './UserContext'
import esES from 'antd/es/locale/es_ES'

import './assets/app.less'

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include'
})

const Root = () => {
  return (
    <ApolloProvider client={client}>
      <SettingsContextProvider>
        <UserContextProvider>
          <ConfigProvider locale={esES}>
            <App />
          </ConfigProvider>
        </UserContextProvider>
      </SettingsContextProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))
