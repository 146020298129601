import {CREATE_PAY_CLAUSE_DEAL, USER} from '../../Platform/queries'
import {Modal, Typography, notification} from 'antd'
import React, {useContext, useEffect, useState} from 'react'

import {SettingsContext} from '../../SettingsContext'
import {UserContext} from '../../UserContext'
import {useMutation} from '@apollo/client'

export default ({visible, player, price, onOk, onCancel, refetchCallback}) => {
  const settingsContext = useContext(SettingsContext)
  const user = useContext(UserContext)
  const [finalPrice, setFinalPrice] = useState(false)
  const [payClause, {loading}] = useMutation(CREATE_PAY_CLAUSE_DEAL, {
    fetchPolicy: 'no-cache',
    refetchQueries: [{query: USER}]
  })

  const handleModal = async () => {
    if (finalPrice === 0) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description: 'El precio no es correcto. Consulta con el administrador.'
      })

      return
    }

    if (finalPrice > user.team.budget) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description:
          'Tu equipo no tiene suficiente presupuesto para comprar al jugador.'
      })

      return
    }

    try {
      await payClause({
        variables: {
          playerId: player.id
        }
      })

      if (refetchCallback) {
        refetchCallback()
      }
      onOk()
    } catch (err) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description: err.graphQLErrors[0].message
      })
    }
  }

  useEffect(() => {
    if (settingsContext && settingsContext.settings && player) {
      setFinalPrice(
        parseFloat(
          (
            (player.clause === 0 ? price : player.clause) *
            ((100 + settingsContext.settings.clausesTaxes) / 100)
          ).toFixed(2)
        )
      )
    }
  }, [settingsContext, player, price])

  return (
    <Modal
      title="Confirmación de compra"
      visible={visible}
      okText="Comprar"
      cancelText="Cancelar"
      onOk={handleModal}
      onCancel={onCancel}
      okButtonProps={{disabled: loading, loading}}
      cancelButtonProps={{disabled: loading}}
    >
      ¿Estás seguro de que quieres pagar{' '}
      <Typography.Text strong>{finalPrice} M</Typography.Text> (100% de la
      cláusula + IVA) por la transacción a cambio de {player.name}?
    </Modal>
  )
}
