import {Col, Input, InputNumber, Row, Select, Table} from 'antd'
import {
  GET_PLAYERS_WITH_TEAMS,
  GET_TEAMS_WITH_MANAGERS,
  SET_PLAYER_CLAUSE,
  SET_PLAYER_TEAM
} from '../../queries'
import React, {useEffect, useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'

export default ({activeTabKey}) => {
  const handleTeamChange = async (playerId, teamId) => {
    await setPlayerTeam({
      variables: {
        playerId,
        teamId
      }
    })
    await refetchTeams()
    setTableLoading(false)
  }

  const handleClauseChange = async (playerId, clause) => {
    clause = parseInt(clause.replace(' M'))
    await setPlayerClause({
      variables: {
        playerId,
        clause
      }
    })
    await refetchTeams()
    setTableLoading(false)
  }

  const searchPlayers = () => {
    return !search || search === ''
      ? players
      : players.filter(player =>
          player.name.toLowerCase().includes(search.toLowerCase())
        )
  }

  const columns = [
    {
      title: 'Jugador',
      dataIndex: 'name'
    },
    {
      title: 'Cláusula',
      render: elem => (
        <InputNumber
          style={{width: '100%'}}
          placeholder="Cláusula de rescisión"
          defaultValue={elem.clause}
          formatter={value => `${value} M`}
          parser={value => value.replace(' M', '')}
          onPressEnter={e => handleClauseChange(elem.id, e.target.value)}
        />
      )
    },
    {
      title: 'Equipo',
      render: elem => {
        const teamsToRender =
          teams &&
          teams.length > 0 &&
          teams.map(team => (
            <Select.Option key={team.id}>{team.name}</Select.Option>
          ))

        return (
          <span>
            {elem && elem.team ? (
              <Select
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                onChange={value => handleTeamChange(elem.id, value)}
                defaultValue={elem.team.name}
                placeholder="Equipo al que pertenece el jugador"
                showSearch
                style={{width: '100%'}}
              >
                {teamsToRender}
              </Select>
            ) : (
              <Select
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                onChange={value => handleTeamChange(elem.id, value)}
                placeholder="Equipo al que pertenece el jugador"
                showSearch
                style={{width: '100%'}}
              >
                {teamsToRender}
              </Select>
            )}
          </span>
        )
      }
    }
  ]

  const [players, setPlayers] = useState([])
  const [teams, setTeams] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [search, setSearch] = useState(false)

  const {data, error, loading, refetch} = useQuery(GET_PLAYERS_WITH_TEAMS, {
    fetchPolicy: 'network-only'
  })
  const {
    data: teamsData,
    error: teamsError,
    loading: teamsLoading,
    refetch: refetchTeams
  } = useQuery(GET_TEAMS_WITH_MANAGERS, {
    fetchPolicy: 'network-only'
  })
  const [setPlayerTeam] = useMutation(SET_PLAYER_TEAM, {
    fetchPolicy: 'no-cache'
  })
  const [setPlayerClause] = useMutation(SET_PLAYER_CLAUSE, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (data && !error) {
      setPlayers(data.players)
    }
  }, [data, error])

  useEffect(() => {
    if (teamsData && !teamsError) {
      setTeams(teamsData.teams)
    }
  }, [teamsData, teamsError])

  useEffect(() => {
    if (
      activeTabKey === '3' &&
      data &&
      !loading &&
      teamsData &&
      !teamsLoading
    ) {
      refetch()
      refetchTeams()
    }
    // eslint-disable-next-line
  }, [activeTabKey])

  return (
    <div id="players-list">
      <Row style={{marginBottom: '1.5vh'}}>
        <Col md={{span: 8, offset: 16}}>
          <Input.Search
            placeholder="Filtra jugadores por su nombre"
            onChange={e => setSearch(e.target.value)}
          />
        </Col>
      </Row>

      <Table
        size="middle"
        rowKey={'id'}
        dataSource={searchPlayers()}
        loading={tableLoading}
        columns={columns}
      />
    </div>
  )
}
