import {Badge, Icon, Layout, Menu} from 'antd'
import {Link, withRouter} from 'react-router-dom'
import React, {useContext, useEffect, useState} from 'react'

import {UserContext} from '../UserContext'
import logo from '../assets/img/logo.svg'

export default withRouter(({location}) => {
  const user = useContext(UserContext)
  const [noOfDeals, setNoOfDeals] = useState(0)
  const [initialMenuItem, setInitialMenuItem] = useState(false)

  useEffect(() => {
    const deals = []

    if (
      user &&
      user.team &&
      user.team.players &&
      user.team.players.length > 0
    ) {
      user.team.players.forEach(player =>
        player.offerRequests.forEach(deal => {
          if (deal.type === 'AGREEMENT' && deal.status === 'PENDING') {
            deals.push(deal)
          }
        })
      )
    }

    setNoOfDeals(deals.length)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    switch (window.location.pathname) {
      case '/actividad':
        setInitialMenuItem('5')
        break
      case '/mercado':
        setInitialMenuItem('3')
        break
      case '/fichajes':
        setInitialMenuItem('4')
        break
      case '/mi-usuario':
        setInitialMenuItem('6')
        break
      case '/admin':
        setInitialMenuItem(user && user.isAdmin() ? '7' : '1')
        break
      case '/mi-equipo':
        setInitialMenuItem('2')
        break
      default:
        setInitialMenuItem('1')
        break
    }
    //eslint-disable-next-line
  }, [location.pathname])

  return (
    <Layout.Header id="header" style={{zIndex: 3}}>
      <div id="desktop-menu">
        <div style={{width: '2.5vw', float: 'left', marginRight: '1vw'}}>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[initialMenuItem]}
          style={{lineHeight: '64px', display: 'flex'}}
        >
          <Menu.Item key="1">
            <Link to="/">Inicio</Link>
          </Menu.Item>
          {user && user.team && (
            <Menu.Item key="2">
              <Link to="/mi-equipo">Mi equipo</Link>
            </Menu.Item>
          )}
          {user && user.team && (
            <Menu.Item key="3">
              <Link to="/mercado">Mercado</Link>
            </Menu.Item>
          )}
          {user && user.team && user.team.players && (
            <Menu.Item key="4">
              <Link to="/fichajes">
                <Badge count={noOfDeals} overflowCount={9} offset={[8, -6]}>
                  Fichajes
                </Badge>
              </Link>
            </Menu.Item>
          )}
          {user && user.team && (
            <Menu.Item key="5">
              <Link to="/actividad">Actividad</Link>
            </Menu.Item>
          )}

          <Menu.Item style={{marginLeft: 'auto'}} key="6">
            <Link to="/mi-usuario">
              <Icon style={{fontSize: '1.25rem'}} type="user" />
              Mi perfil
            </Link>
          </Menu.Item>

          {user && user.isAdmin() && (
            <Menu.Item key="7">
              <Link to="/admin">
                <Icon style={{fontSize: '1.25rem'}} type="unlock" />
                Admin
              </Link>
            </Menu.Item>
          )}

          <Menu.Item key="8">
            <Icon
              style={{fontSize: '1.25rem'}}
              type="logout"
              onClick={user.signOut}
            />
          </Menu.Item>
        </Menu>
      </div>
      <div id="mobile-menu" style={{display: 'none'}}>
        <div style={{width: '7.5vw', float: 'left', marginRight: '1vw'}}>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[initialMenuItem]}
          style={{
            lineHeight: '64px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Menu.SubMenu
            title={
              <Icon style={{fontSize: '1.25rem', marginRight: 0}} type="menu" />
            }
          >
            <Menu.Item key="1">
              <Link to="/">Inicio</Link>
            </Menu.Item>
            {user && user.team && (
              <Menu.Item key="2">
                <Link to="/mi-equipo">Mi equipo</Link>
              </Menu.Item>
            )}
            {user && user.team && (
              <Menu.Item key="3">
                <Link to="/mercado">Mercado</Link>
              </Menu.Item>
            )}
            {user && user.team && user.team.players && (
              <Menu.Item key="4">
                <Link to="/fichajes">Fichajes</Link>
              </Menu.Item>
            )}
            {user && user.team && (
              <Menu.Item key="5">
                <Link to="/actividad">Actividad</Link>
              </Menu.Item>
            )}

            <Menu.Item style={{marginLeft: 'auto'}} key="6">
              <Link to="/mi-usuario">Mi perfil</Link>
            </Menu.Item>

            {user && user.isAdmin() && (
              <Menu.Item key="7">
                <Link to="/admin">Admin</Link>
              </Menu.Item>
            )}

            <Menu.Item key="8">
              <div onClick={user.signOut}>Cerrar sesión</div>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
    </Layout.Header>
  )
})
