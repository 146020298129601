import {Button, Select, Switch, Table, Tooltip, notification} from 'antd'
import {GET_TEAMS, SET_USER_TEAM, USER} from '../../queries'
import React, {useEffect, useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'

import {gql} from 'apollo-boost'

const GET_USERS = gql`
  query getUsers {
    getUsers {
      id
      username
      role
      team {
        id
        name
      }
    }
  }
`

const RESET_PASSWORD = gql`
  mutation resetPassword($userId: String!) {
    resetPassword(userId: $userId)
  }
`

const SET_ADMIN_PRIVILEGES = gql`
  mutation setAdminPrivileges($userId: String!, $isAdmin: Boolean!) {
    setAdminPrivileges(userId: $userId, isAdmin: $isAdmin)
  }
`

export default () => {
  const handleAdminSwitch = async (userId, isAdmin) => {
    setTableLoading(true)
    await setIsAdmin({
      variables: {
        userId,
        isAdmin
      }
    })
    setTableLoading(false)
  }

  const handleTeamChange = async (userId, teamId) => {
    setTableLoading(true)

    if (!teamId) {
      teamId = 'REMOVE'
    }

    await setUserTeam({
      variables: {
        userId,
        teamId
      }
    })
    await refetchTeams()
    setTableLoading(false)
  }

  const handleResetPassword = async userId => {
    try {
      await resetPassword({
        variables: {
          userId
        }
      })

      notification.success({
        message: '¡Éxito!',
        description: 'Se ha cambiado la contraseña del usuario correctamente.'
      })
    } catch (err) {
      notification.error({
        message: '¡Ha ocurrido un error!',
        description: err.graphQLErrors[0].message
      })
    }
  }

  const columns = [
    {
      title: 'Nombre de usuario',
      dataIndex: 'username'
    },
    {
      title: 'Administrador',
      render: elem => (
        <Switch
          onChange={checked => handleAdminSwitch(elem.id, checked)}
          checkedChildren="On"
          unCheckedChildren="Off"
          defaultChecked={elem.role && elem.role === 'admin'}
        />
      )
    },
    {
      title: 'Equipo',
      render: elem => {
        const teamsToRender =
          teams &&
          teams.length > 0 &&
          teams
            .filter(team => team.manager === null)
            .map(team => (
              <Select.Option key={team.id}>{team.name}</Select.Option>
            ))

        return (
          <span>
            {elem && elem.team ? (
              <Select
                allowClear
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                onChange={value => handleTeamChange(elem.id, value)}
                defaultValue={elem.team.name}
                placeholder="Equipo que gestiona el usuario"
                showSearch
                style={{width: '100%'}}
              >
                {teamsToRender}
              </Select>
            ) : (
              <Select
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                onChange={value => handleTeamChange(elem.id, value)}
                placeholder="Equipo que gestiona el usuario"
                showSearch
                style={{width: '100%'}}
              >
                {teamsToRender}
              </Select>
            )}
          </span>
        )
      }
    },
    {
      title: '',
      render: elem => (
        <div style={{textAlign: 'right'}}>
          <Tooltip title={<span>Reiniciar contraseña</span>}>
            <Button
              loading={resetPasswordLoading}
              disabled={resetPasswordLoading}
              type="primary"
              onClick={() => handleResetPassword(elem.id)}
              icon="redo"
              style={{width: '36px', height: '36px'}}
            />
          </Tooltip>
        </div>
      )
    }
  ]

  const [users, setUsers] = useState([])
  const [teams, setTeams] = useState([])
  const [tableLoading, setTableLoading] = useState(false)

  const {data, error, loading} = useQuery(GET_USERS, {
    fetchPolicy: 'network-only'
  })
  const {data: teamsData, error: teamsError, refetch: refetchTeams} = useQuery(
    GET_TEAMS,
    {
      fetchPolicy: 'network-only'
    }
  )
  const [setIsAdmin] = useMutation(SET_ADMIN_PRIVILEGES, {
    fetchPolicy: 'no-cache',
    refetchQueries: [{query: USER}]
  })
  const [setUserTeam] = useMutation(SET_USER_TEAM, {
    fetchPolicy: 'no-cache',
    refetchQueries: [{query: USER}]
  })
  const [resetPassword, {loading: resetPasswordLoading}] = useMutation(
    RESET_PASSWORD,
    {
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    if (data && !error) {
      setUsers(data.getUsers.map(item => item))
    }
  }, [data, error])

  useEffect(() => {
    if (teamsData && !teamsError) {
      setTeams(teamsData.teams.map(item => item))
    }
  }, [teamsData, teamsError])

  return (
    <div id="users-list">
      <Table
        size="middle"
        rowKey={'id'}
        dataSource={users}
        loading={loading || tableLoading}
        columns={columns}
      />
    </div>
  )
}
